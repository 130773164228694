.languages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20rem;
  }

  &__title {
    margin: 20rem 0;
    font-size: 34rem;
  }

  &__type {
    transition: all 150ms ease-in;
    filter: var(--shadow-filter);
    opacity: 0.3;

    &--image {
      border-radius: 50%;
      max-width: 72rem;
      border: 4rem solid var(--secondary);
    }

    &--active {
      transform-origin: center center;
      transform: scale(1.3);
      opacity: 1;
      filter: none;

      .languages__type--image {
        border: 4rem solid var(--accent);
      }
    }
  }
}
