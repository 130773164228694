.suggestion-sales {
  &__page-container {
    padding: 100rem 0 0 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30rem;

    &--button {
      position: relative;
      right: 250rem;
    }
  }

  &__actions {
    gap: 100rem;
  }

  &--hidden {
    display: none;
  }
}
