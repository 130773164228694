.buttons-counter-edit {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20rem 0;

    &--amount {
      width: 80rem;
      text-align: center;
    }

    &--plus {
      width: 52rem;
      height: 52rem;
    }

    &--button:disabled {
      opacity: 0.7;
    }
  }
}
