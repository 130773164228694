.promos-select-method {
  &__option-box {
    width: 340rem;
    height: 340rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 20rem;
    margin-top: 34rem;
    height: 100%;
  }

  &__voucher-input {
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
  }
}
