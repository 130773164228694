.osiris-order-history {
  background: var(--primary);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40rem 0;
  }

  &__orders {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 40rem;
    flex: 1;
  }

  &__footer {
    background: var(--primary);
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 210rem;
    padding-left: 80rem;
    padding-right: 30rem;
    align-items: center;
    justify-items: center;
    position: relative;
  }

  &--generic-text {
    color: var(--secondary);
    margin: auto 60rem;
    font-size: 56rem;
  }

  &__server-timeout-message,
  &__spinner-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer {
    background: var(--footerDarkBackgroundColor);

    &__ada-button-image {
      display: none;

      &--light {
        display: block;
      }
    }
  }
}
