.service-type-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  border-radius: 20rem;
  padding: 30rem;

  background: var(--secondary);
  filter: var(--shadow-filter);

  &__img {
    align-self: center;
    margin: 64rem;
    width: 272rem;
    height: 272rem;
  }

  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    width: 100%;

    &--title {
      line-height: 1.1;
      font-size: 46rem;
    }
  }
}

.ada {
  .service-type-button {
    &__text {
      padding: 0;

      &--title {
        font-size: 38rem;
      }
    }

    &__img {
      max-width: 65%;
    }
  }
}
