.osiris-login {
  &__osiris-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex: 1;
    gap: 30rem;
    padding: 0 100rem 100rem 100rem;

    &--button {
      background: var(--secondary);
      filter: var(--shadow-filter);
      border-radius: 18rem;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 30rem;
      gap: 40rem;
    }

    &--title {
      font-size: 64rem;
      justify-self: flex-start;
      display: flex;
      gap: 11rem;
    }
  }
}

.ada {
  .osiris-login {
    &__osiris-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      flex: 0;
      margin: auto 0;
      padding: 0 40rem;

      &--button {
        padding: 0 15rem;
      }

      &--img {
        max-width: 140rem;
      }

      &--title {
        font-size: 48rem;
      }
    }

    .page-header__title {
      padding: 24rem;
    }
  }
}
