.menu-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.menu {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 40rem;
    flex: 120rem 0 0;
    position: relative;

    .ui-button--menu-back {
      position: absolute;
      left: 0;
    }

    // &.navBarButtonsAvailable {
    //   padding: 0 40rem 20rem 40rem;
    // }
  }

  &__populars-title {
    padding: 0 40rem 40rem 40rem;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: var(--accent);
  }

  .top-menu {
    height: 120rem;
  }

  .footer {
    background: var(--footerDarkBackgroundColor);

    &__ada-button-image {
      display: none;

      &--light {
        display: block;
      }
    }
  }
}

.rtl {
  .menu__header {
    & > button {
      right: 0;
      left: unset;
    }
  }
}
