.ui-button {
  text-transform: uppercase;
  line-height: unset;
  min-height: 70rem;
  padding: 10rem 35rem;
  border-radius: 70rem;
  font-weight: normal;
  font-size: 30rem;
  transition: all 150ms linear;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  box-sizing: border-box;
  height: unset;

  &--primary {
    background: var(--uiButtonPrimaryBackgroundColor);
    color: var(--uiButtonPrimaryColor);
    border: 4rem solid var(--uiButtonPrimaryBorderColor);
  }

  &--secondary {
    background: var(--uiButtonSecondaryBackgroundColor);
    color: var(--uiButtonSecondaryColor);
    border: 4rem solid var(--uiButtonSecondaryBorderColor);
  }

  &--tertiary {
    background: var(--uiButtonTertiaryBackgroundColor);
    color: var(--uiButtonTertiaryColor);
    border: 4rem solid var(--uiButtonTertiaryBorderColor);
  }

  &--color-lightGray {
    color: var(--lightGray);
  }

  &--bg-white {
    background: var(--secondary);
    border-color: var(--secondary);
    color: var(--primary);
  }

  &--link {
    min-width: unset;
    height: unset;
    color: var(--uiButtonLinkColor);
    padding: 10rem 25rem;
  }

  &--tiny {
    min-height: 52rem;
    padding: 6rem 24rem;
  }

  &--small {
    min-width: 220rem;
  }

  &--medium {
    min-width: 290rem;
  }

  &--large {
    min-width: 390rem;
  }

  &--x-large {
    width: 340rem;
    height: 86rem;
  }

  &--xx-large {
    width: 390rem;
    height: 112rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 4rem;
  }

  &--icon-button {
    min-width: unset;
    height: unset;
    color: var(--iconPrimaryColor);
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: inherit;
  }

  &--icon {
    margin: 0 10rem;
  }

  &--iconLeft {
    .ui-button--icon {
      margin-left: -10rem;
    }
  }

  &--iconRight {
    .ui-button__wrapper {
      flex-direction: row-reverse;
    }

    .ui-button--icon {
      margin-right: -10rem;
      margin-left: 5rem;
    }
  }
}
