.payment-retry {
  &__wrapper {
    padding: 120rem 30rem;
    &--title {
      font-size: 58rem;
      max-width: 80%;
      margin: 0 auto;
    }
  }

  &__actions {
    padding: 20rem;

    &--button {
      min-width: 340rem;
      height: 84rem;
      font-size: 34rem;
    }
  }
}
