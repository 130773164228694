.make-it-a-meal {
  &__title {
    text-align: center;
    padding: 60rem;
    margin-bottom: 0;
  }

  &__wrapper {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    padding: 30rem 5rem 60rem 5rem;
  }

  &__product {
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 30rem 40rem;
    gap: 30rem;

    &--img {
      max-width: 200rem;
    }

    &--title {
      font-size: 46rem;
      text-align: left;
    }

    &--info {
      position: absolute;
      right: 10rem;
      top: 20rem;
    }
  }

  &__actions {
    gap: 100rem;
    justify-content: center;
  }

  .scrollable-container {
    height: unset;
  }
}
