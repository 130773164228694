.button-details {
  &__container {
    overflow-y: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;

    &--product {
      --product-border-radius: 20rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: normal;
      width: 300rem;
      margin: 10rem auto;
      border-radius: var(--product-border-radius);
      padding: 25rem;
      filter: var(--shadow-filter);
      color: var(--tertiary);
      background-color: var(--secondary);
      position: relative;
    }

    &--product-title {
      width: 100%;
      text-align: left;
      font-size: 32rem;
      line-height: 1;
      padding-right: 28rem;
    }

    &--product-img {
      max-width: 200rem;
      margin: 10rem auto;
    }

    &--info {
      position: absolute;
      top: 10px;
      right: 0;
    }
  }
}

.rtl {
  .button-details {
    &__header {
      &--info {
        left: 0;
        right: unset;
        margin-left: 10rem;
        margin-right: 0;
      }

      &--product-title {
        text-align: right;
        padding-left: 28rem;
        padding-right: unset;
      }
    }
  }
}
