.collapsible {
  padding: 10rem 30rem;

  &__content {
    overflow: hidden;
    background: var(--secondary);
    margin: 8rem 0;
  }

  &__title {
    font-size: 30rem;
    position: relative;
  }

  &__toggle {
    font-size: 30rem;
    width: 100%;
    padding: 20rem;
    filter: var(--shadow-filter);
    background: var(--secondary);
    border-bottom-left-radius: 20rem;
    border-bottom-right-radius: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &--arrow {
      position: absolute;
      top: 10rem;
      right: -36rem;
    }
  }
}
