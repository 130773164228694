@charset "UTF-8";
@font-face {
  font-family: "DOT Default";
  src: url("/Channels/skin/default.woff");
  font-style: normal;
}
@font-face {
  font-family: "DOT Light";
  src: url("/Channels/skin/light.woff");
  font-style: normal;
}
@font-face {
  font-family: "DOT Medium";
  src: url("/Channels/skin/secondary.otf");
  font-style: normal;
}
:root {
  --fontDefault: "DOT Default";
  --fontSecondary: "DOT Medium";
  --fontLight: "DOT Light";
  --primary: #252323;
  --secondary: #ffffff;
  --accent: #cbe510;
  --mainBackgroundColor: #ffffff;
  --basketBackgroundColor: #252323;
  --codViewBackgroundColor: #252323;
  --footerLightBackgroundColor: #ffffff;
  --footerDarkBackgroundColor: #252323;
  --popupsFooterBackgroundColor: #252323;
  --popupsContentBackgroundColor: #ffffff;
  --uiButtonPrimaryColor: #ffffff;
  --uiButtonPrimaryBackgroundColor: #252323;
  --uiButtonPrimaryBorderColor: #ffffff;
  --uiButtonSecondaryColor: #252323;
  --uiButtonSecondaryBackgroundColor: #cbe510;
  --uiButtonSecondaryBorderColor: transparent;
  --uiButtonTertiaryBackgroundColor: transparent;
  --uiButtonTertiaryColor: #252323;
  --uiButtonTertiaryBorderColor: #252323;
  --uiButtonLinkColor: #b5b5b5;
  --iconPrimaryColor: #252323;
  --iconSecondaryColor: #ffffff;
  --iconQuantityColor: #b5b5b5;
  --navSliderBackground: #252323;
  --navSliderItemTitleColor: #ffffff;
  --navSliderItemSelectedBackground: #cbe510;
  --navSliderItemSelectedTitleColor: #252323;
  --comboStepperMeterColor: #959595;
  --black: #000000;
  --blue: #00b0f0;
  --purple: #c153cb;
  --green: #6bbe4e;
  --orange: #ee5b04;
  --shadow: #00000080;
  --tertiary: #252323;
  --red: #dd0017;
  --errorRed: #ff2b2b;
  --gray: #e5e5e5;
  --keyboardGray: #959595;
  --white: #ffffff;
  --darkGray: #323231;
  --lightGray: #b5b5b5;
  --backgroundLightGray: #f0ece3;
  --backgroundGray: #f4f4f4;
  --footer-height: 170rem;
  --spinner-color: #898989;
  --box-shadow: 0 0 21rem 5rem rgba(204, 204, 204, 1);
  --shadow-filter: drop-shadow(0 0 4rem rgba(85, 34, 34, 0.17));
  --button-radius: 2rem;
  --popup-bg: rgba(0, 0, 0, 0.65);
  --banners-bg: #252323;
  /* same as --darkGray but as rgba */
  --darkGray-opacity-5: rgba(50, 50, 49, 0.5);
  --product-bg: url("/assets/images/product-bg.png");
  --combo-bg: url("/Channels/skin/icon-combo-bg.png");
  --pay-tower-bg: url("/Channels/skin/image--pay-tower.png");
  --logo: var(--customLogo, url("/Channels/skin/logo.svg"));
}

.ui-button {
  text-transform: uppercase;
  line-height: unset;
  min-height: 70rem;
  padding: 10rem 35rem;
  border-radius: 70rem;
  font-weight: normal;
  font-size: 30rem;
  transition: all 150ms linear;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  box-sizing: border-box;
  height: unset;
}
.ui-button--primary {
  background: var(--uiButtonPrimaryBackgroundColor);
  color: var(--uiButtonPrimaryColor);
  border: 4rem solid var(--uiButtonPrimaryBorderColor);
}
.ui-button--secondary {
  background: var(--uiButtonSecondaryBackgroundColor);
  color: var(--uiButtonSecondaryColor);
  border: 4rem solid var(--uiButtonSecondaryBorderColor);
}
.ui-button--tertiary {
  background: var(--uiButtonTertiaryBackgroundColor);
  color: var(--uiButtonTertiaryColor);
  border: 4rem solid var(--uiButtonTertiaryBorderColor);
}
.ui-button--color-lightGray {
  color: var(--lightGray);
}
.ui-button--bg-white {
  background: var(--secondary);
  border-color: var(--secondary);
  color: var(--primary);
}
.ui-button--link {
  min-width: unset;
  height: unset;
  color: var(--uiButtonLinkColor);
  padding: 10rem 25rem;
}
.ui-button--tiny {
  min-height: 52rem;
  padding: 6rem 24rem;
}
.ui-button--small {
  min-width: 220rem;
}
.ui-button--medium {
  min-width: 290rem;
}
.ui-button--large {
  min-width: 390rem;
}
.ui-button--x-large {
  width: 340rem;
  height: 86rem;
}
.ui-button--xx-large {
  width: 390rem;
  height: 112rem;
}
.ui-button__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4rem;
}
.ui-button--icon-button {
  min-width: unset;
  height: unset;
  color: var(--iconPrimaryColor);
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: inherit;
}
.ui-button--icon {
  margin: 0 10rem;
}
.ui-button--iconLeft .ui-button--icon {
  margin-left: -10rem;
}
.ui-button--iconRight .ui-button__wrapper {
  flex-direction: row-reverse;
}
.ui-button--iconRight .ui-button--icon {
  margin-right: -10rem;
  margin-left: 5rem;
}

html {
  overflow: hidden;
  touch-action: none;
  font-size: 0.0520833333vh;
}

body {
  position: relative;
  margin: auto;
  height: 100vh;
  overflow: hidden;
  font-size: 18rem;
  background: var(--mainBackgroundColor);
  font-family: var(--fontDefault);
  color: var(--primary);
  aspect-ratio: 9/16;
}
body.kioskDevice {
  width: 100%;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--primary);
  font-weight: normal;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-container--grid {
  display: grid;
  height: 100%;
}

.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100rem;
}
.page-header__title {
  text-align: center;
  padding: 40rem;
}

.page-footer {
  display: flex;
  align-items: center;
  padding: 0 50rem;
  height: 170rem;
}

.grid {
  display: grid;
  place-items: center;
  grid-auto-rows: 1fr;
}

.rtl {
  direction: rtl;
}

.circle {
  width: 600rem;
  height: 600rem;
  padding: 30rem;
  border-radius: 50%;
  background: var(--primary);
  filter: var(--shadow-filter);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle--medium {
  width: 360rem;
  height: 360rem;
}
.circle--small {
  width: 300rem;
  height: 300rem;
}

.square {
  background: var(--primary);
  filter: var(--shadow-filter);
  border-radius: 20rem;
  padding: 80rem;
  position: relative;
}
.square > * {
  color: var(--secondary);
}

.popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--popup-bg);
}
.popup__content {
  padding: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  max-height: 95%;
  border-radius: 20rem;
  background: var(--popupsContentBackgroundColor, var(--secondary));
}
.popup__page-container {
  padding: 100rem 50rem;
  overflow-y: auto;
}
.popup__title {
  margin-bottom: 30rem;
}
.popup__actions {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 150rem;
  padding: 0 30rem;
  border-bottom-left-radius: 18rem;
  border-bottom-right-radius: 18rem;
  background: var(--popupsFooterBackgroundColor, var(--primary));
  justify-content: space-between;
}
.popup__actionsAllergens {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 150rem;
  padding: 0 30rem;
  border-bottom-left-radius: 18rem;
  border-bottom-right-radius: 18rem;
  background: var(--popupsFooterBackgroundColor, var(--primary));
  justify-content: center;
}
.popup .scroll-nav {
  border-bottom: 0;
}

.product {
  --product-border-radius: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25rem;
  width: 100%;
  height: 100%;
  position: relative;
  filter: var(--shadow-filter);
  color: var(--tertiary);
  background-color: var(--secondary);
  border-radius: var(--product-border-radius);
}
.product--disabled {
  filter: none;
  background-color: var(--gray);
}
.product--disabled > :not(.disabled--overlay) {
  opacity: 0.5;
}
.product--hasBackground {
  background-image: var(--product-bg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}
.product--hasChargeThreshold .product__subtitle {
  position: absolute;
  left: 0;
  right: 0;
  line-height: 1;
}
.product--displayOnlyImage {
  flex-direction: row;
  padding: 0;
  align-items: center;
}
.product--displayOnlyImage .product {
  padding: unset;
}
.product--displayOnlyImage .product__img {
  width: unset;
  height: unset;
  max-width: 100%;
  margin: 0 auto;
  padding: unset;
  border-radius: inherit;
}
.product--displayOnlyImage .product__info, .product--displayOnlyImage .product__title {
  display: none;
}
.product--displayOnlyText .product__title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}
.product--displayOnlyText .product__img {
  display: none;
}
.product::after {
  content: "";
  bottom: -30rem;
  width: 0;
  height: 0;
  opacity: 0;
  border-left: 30rem solid transparent;
  border-right: 30rem solid transparent;
  border-top: 30rem solid transparent;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-out;
}
.product.selected {
  box-shadow: inset 0 0 0 5rem var(--accent);
}
.product.selected::after {
  border-top: 30rem solid var(--accent);
  opacity: 1;
}
.product__header {
  position: relative;
  margin-bottom: 30rem;
  width: 100%;
}
.product__button {
  z-index: 1;
}
.product__title {
  width: 100%;
  text-align: left;
  font-size: 32rem;
  line-height: 1;
}
.product__img {
  max-width: 200rem;
}
.product__info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product__info--calories {
  font-family: var(--font-secondary);
  font-size: 24rem;
}
.product__info--calories-count {
  margin-right: 5rem;
}
.product__info--icon {
  margin-left: auto;
}
.product__popular-button {
  background: var(--accent);
}
.product--modifier button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: inherit;
}
.product--modifier.selected {
  box-shadow: inset 0 0 0 5rem var(--accent);
  filter: none;
}
.product--modifier.selected::after {
  display: none;
}
.product--modifier.selected .modifier-checkmark {
  display: block;
}
.product--modifier .product__title {
  text-align: center;
  font-size: 24rem;
}
.product--modifier .product__subtitle {
  font-family: var(--font-secondary);
  font-size: 24rem;
  margin: 5rem auto;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  line-height: 1;
}
.product--modifier .product__img {
  max-width: 180rem;
}
.product--modifier .modifier-checkmark {
  display: none;
  position: absolute;
  top: -30rem;
  right: -20rem;
}
.product--modifier .modifier-checkmark use {
  --icon-primary-color: var(--tertiary);
  --icon-secondary-color: var(--secondary);
}

.disabled--overlay {
  background-color: var(--dark-gray-opacity-5);
  color: var(--secondary);
  position: absolute;
  z-index: 1;
  opacity: 1;
  transform: translate(2.5%, -50%);
  width: 95%;
  font-size: 40rem;
  font-weight: 100;
  text-align: center;
  padding: 30rem 0;
  top: 50%;
  left: 0;
}

.navBarVerticalOrientation .product__img {
  width: unset;
  height: unset;
  max-width: 150rem;
}

.menu-buttons-grid {
  display: grid;
  grid-gap: 30rem;
  grid-template-rows: minmax(300rem, 1fr);
  width: 100%;
  padding: 8rem 40rem;
}
.menu-buttons-grid > * {
  width: 100%;
  height: 100%;
}

.modifiers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 260rem);
  grid-auto-rows: minmax(370rem, 1fr);
  justify-content: center;
  gap: 30rem;
  padding: 30rem 40rem;
  width: 100%;
}
.modifiers-grid .product {
  padding: 24rem 15rem;
}
.modifiers-grid.dual-modifiers {
  grid-template-columns: 1fr 1fr;
  max-width: 70%;
  margin: 60rem auto;
  grid-gap: 60rem;
  padding: 20rem 70rem;
}
.modifiers-grid.dual-modifiers .product.selected {
  transform: scale(1.15);
}

.highlighted-items {
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 40rem;
}
.highlighted-items :nth-child(2n+1):last-child {
  grid-column: 1/-1;
}

.stepper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-content: center;
  padding: 30rem 10rem;
  height: 100%;
}
.stepper__step {
  display: none;
  justify-items: center;
  align-items: flex-start;
  grid-template-rows: 60rem auto;
  height: 100%;
  text-align: center;
  position: relative;
}
.stepper__step--visible {
  display: grid;
}
.stepper__step--last-item::after {
  display: none;
}
.stepper__step:last-of-type::after {
  display: none;
}
.stepper__step::after {
  content: "";
  position: absolute;
  height: 10rem;
  width: 100%;
  right: -50%;
  bottom: 35rem;
  background: linear-gradient(to left, var(--comboStepperMeterColor) 50%, var(--accent) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 800ms ease;
  z-index: 0;
}
.stepper__step--name {
  font-size: 18rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  color: var(--comboStepperMeterColor);
  font-family: var(--font-tertiary);
  transition: color 250ms linear;
}
.stepper__step--name::after {
  content: "";
  display: inline-block;
  height: 6rem;
  width: 0;
  border-radius: 4rem;
  background: var(--accent);
}
.stepper__step--circle {
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  transition: all 200ms ease-in-out;
  border: 5rem solid var(--comboStepperMeterColor);
  background: var(--primary);
  z-index: 1;
}
.stepper__step--circle .stepper__step--icon {
  display: none;
}
.stepper__step--circle .stepper__step--image {
  max-width: 46rem;
  padding: 6rem;
  animation: scaleInCenter 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.stepper__step--selected::after {
  background-position: left bottom;
}
.stepper__step--selected .stepper__step--circle {
  border-color: var(--secondary);
  background: var(--secondary);
}
.stepper__step--selected .stepper__step--circle .stepper__step--icon {
  display: inline-block;
}
.stepper__step--active .stepper__step--name {
  color: var(--secondary);
}
.stepper__step--active .stepper__step--name::after {
  width: 100%;
  animation: scaleInHorCenter 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.stepper__step--active .stepper__step--circle {
  border-color: var(--secondary);
  background: var(--secondary);
  transform: scale(1.8);
}

/*

#
###
##### GENERAL
###
#

*/
h1 {
  font-size: 80rem;
}

h2 {
  font-size: 60rem;
}

h3 {
  font-size: 46rem;
}

h4 {
  font-size: 36rem;
}

h5 {
  font-size: 26rem;
}

h6 {
  font-size: 16rem;
}

.big--heading {
  font-size: 230rem;
}

button {
  border: none;
  outline: unset;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  margin: 0;
  padding: 0;
}
button:disabled {
  opacity: 0.4;
  pointer-events: none;
}
button:disabled > * {
  opacity: 0.4;
  pointer-events: none;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

video {
  height: -webkit-fill-available;
}

.logo {
  max-width: 200rem;
  content: var(--logo);
}
.logo.large {
  max-width: 300rem;
}
.logo.medium {
  max-width: 200rem;
}
.logo.small {
  max-width: 100rem;
}

.font--secondary {
  font-family: var(--fontSecondary);
}

.font--tertiary {
  font-family: var(--fontTertiary);
}

.font--light {
  font-family: var(--fontLight);
}

.bold {
  font-weight: bold;
}

/*

#
###
##### HELPERS
###
#

*/
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-full {
  display: flex;
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.scrollY {
  overflow-y: auto;
}

.scrollX {
  overflow-x: overlay;
}

.flex--1 {
  flex: 1;
}

.flex--2 {
  flex: 2;
}

.flex--3 {
  flex: 3;
}

.flex--4 {
  flex: 4;
}

.flex--5 {
  flex: 5;
}

.flex--6 {
  flex: 6;
}

.flex--7 {
  flex: 7;
}

.flex--8 {
  flex: 8;
}

.flex--9 {
  flex: 9;
}

.flex--10 {
  flex: 10;
}

.flex {
  display: flex;
}

.flex--center {
  place-items: center;
  justify-content: center;
}

.flex--column {
  flex-direction: column;
}

.flex--column-reverse {
  flex-direction: column-reverse;
}

.place-center {
  place-items: center;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.self-end {
  justify-self: end;
}

.self-start {
  justify-self: start;
}

.self-center {
  justify-self: center;
}

.align-baseline {
  align-items: baseline;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase-first-letter {
  display: inline-block;
}
.uppercase-first-letter::first-letter {
  text-transform: uppercase;
}

.transform--none {
  text-transform: none;
}

.color--primary {
  color: var(--primary);
}

.color--accent {
  color: var(--accent);
}

.color--red {
  color: var(--red);
}

.color--errorRed {
  color: var(--errorRed);
}

.color--orange {
  color: var(--orange);
}

.color--green {
  color: var(--accent);
}

.color--tertiary {
  color: var(--tertiary);
}

.color--lightGray {
  color: var(--lightGray);
}

.color--white {
  color: var(--secondary);
}

.bg--primary {
  background: var(--primary);
}

.bg--white {
  background: var(--secondary);
}

.bg--orange {
  background: var(--orange);
}

.bg--accent {
  background: var(--accent);
}

.bg--darkGray {
  background: var(--darkGray);
}

.bg--lightGray {
  background: var(--backgroundLightGray);
}

.bg--blue {
  background: var(--blue);
}

.bg--red {
  background: var(--red);
}

.bg--purple {
  background: var(--purple);
}

.height50 {
  height: 50%;
}

.height60 {
  height: 60%;
}

.height70 {
  height: 70%;
}

.height80 {
  height: 80%;
}

.height90 {
  height: 90%;
}

.height100 {
  height: 100%;
}

.width50 {
  width: 50%;
}

.width60 {
  width: 60%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}

.width100 {
  width: 100%;
}

.row-1 {
  grid-row: 1;
}

.row-2 {
  grid-row: 2;
}

.row-3 {
  grid-row: 3;
}

.row-4 {
  grid-row: 4;
}

.row-5 {
  grid-row: 5;
}

.row-6 {
  grid-row: 6;
}

.row-7 {
  grid-row: 7;
}

.row-8 {
  grid-row: 8;
}

.row-9 {
  grid-row: 9;
}

.row-10 {
  grid-row: 10;
}

.row-11 {
  grid-row: 11;
}

.row-12 {
  grid-row: 12;
}

.row-13 {
  grid-row: 13;
}

.row-14 {
  grid-row: 14;
}

.row-15 {
  grid-row: 15;
}

.row-16 {
  grid-row: 16;
}

.row-17 {
  grid-row: 17;
}

.row-18 {
  grid-row: 18;
}

.row-19 {
  grid-row: 19;
}

.row-20 {
  grid-row: 20;
}

.row-21 {
  grid-row: 21;
}

.row-22 {
  grid-row: 22;
}

.row-23 {
  grid-row: 23;
}

.row-24 {
  grid-row: 24;
}

.row-25 {
  grid-row: 25;
}

.row-26 {
  grid-row: 26;
}

.row-27 {
  grid-row: 27;
}

.row-28 {
  grid-row: 28;
}

.row-29 {
  grid-row: 29;
}

.row-30 {
  grid-row: 30;
}

.row-31 {
  grid-row: 31;
}

.row-32 {
  grid-row: 32;
}

.row-33 {
  grid-row: 33;
}

.row-34 {
  grid-row: 34;
}

.row-35 {
  grid-row: 35;
}

.row-36 {
  grid-row: 36;
}

.row-37 {
  grid-row: 37;
}

.row-38 {
  grid-row: 38;
}

.row-39 {
  grid-row: 39;
}

.row-40 {
  grid-row: 40;
}

.col-1 {
  grid-column: 1;
}

.col-2 {
  grid-column: 2;
}

.col-3 {
  grid-column: 3;
}

.col-4 {
  grid-column: 4;
}

.col-5 {
  grid-column: 5;
}

.col-6 {
  grid-column: 6;
}

.col-7 {
  grid-column: 7;
}

.col-8 {
  grid-column: 8;
}

.col-9 {
  grid-column: 9;
}

.col-10 {
  grid-column: 10;
}

.col-11 {
  grid-column: 11;
}

.col-12 {
  grid-column: 12;
}

.col-13 {
  grid-column: 13;
}

.col-14 {
  grid-column: 14;
}

.col-15 {
  grid-column: 15;
}

.col-16 {
  grid-column: 16;
}

.col-17 {
  grid-column: 17;
}

.col-18 {
  grid-column: 18;
}

.col-19 {
  grid-column: 19;
}

.col-20 {
  grid-column: 20;
}

.col-21 {
  grid-column: 21;
}

.col-22 {
  grid-column: 22;
}

.col-23 {
  grid-column: 23;
}

.col-24 {
  grid-column: 24;
}

.col-25 {
  grid-column: 25;
}

.col-26 {
  grid-column: 26;
}

.col-27 {
  grid-column: 27;
}

.col-28 {
  grid-column: 28;
}

.col-29 {
  grid-column: 29;
}

.col-30 {
  grid-column: 30;
}

.col-31 {
  grid-column: 31;
}

.col-32 {
  grid-column: 32;
}

.col-33 {
  grid-column: 33;
}

.col-34 {
  grid-column: 34;
}

.col-35 {
  grid-column: 35;
}

.col-36 {
  grid-column: 36;
}

.col-37 {
  grid-column: 37;
}

.col-38 {
  grid-column: 38;
}

.col-39 {
  grid-column: 39;
}

.col-40 {
  grid-column: 40;
}

.fs--1 {
  font-size: 1rem;
}

.fs--2 {
  font-size: 2rem;
}

.fs--3 {
  font-size: 3rem;
}

.fs--4 {
  font-size: 4rem;
}

.fs--5 {
  font-size: 5rem;
}

.fs--6 {
  font-size: 6rem;
}

.fs--7 {
  font-size: 7rem;
}

.fs--8 {
  font-size: 8rem;
}

.fs--9 {
  font-size: 9rem;
}

.fs--10 {
  font-size: 10rem;
}

.fs--11 {
  font-size: 11rem;
}

.fs--12 {
  font-size: 12rem;
}

.fs--13 {
  font-size: 13rem;
}

.fs--14 {
  font-size: 14rem;
}

.fs--15 {
  font-size: 15rem;
}

.fs--16 {
  font-size: 16rem;
}

.fs--17 {
  font-size: 17rem;
}

.fs--18 {
  font-size: 18rem;
}

.fs--19 {
  font-size: 19rem;
}

.fs--20 {
  font-size: 20rem;
}

.fs--21 {
  font-size: 21rem;
}

.fs--22 {
  font-size: 22rem;
}

.fs--23 {
  font-size: 23rem;
}

.fs--24 {
  font-size: 24rem;
}

.fs--25 {
  font-size: 25rem;
}

.fs--26 {
  font-size: 26rem;
}

.fs--27 {
  font-size: 27rem;
}

.fs--28 {
  font-size: 28rem;
}

.fs--29 {
  font-size: 29rem;
}

.fs--30 {
  font-size: 30rem;
}

.fs--31 {
  font-size: 31rem;
}

.fs--32 {
  font-size: 32rem;
}

.fs--33 {
  font-size: 33rem;
}

.fs--34 {
  font-size: 34rem;
}

.fs--35 {
  font-size: 35rem;
}

.fs--36 {
  font-size: 36rem;
}

.fs--37 {
  font-size: 37rem;
}

.fs--38 {
  font-size: 38rem;
}

.fs--39 {
  font-size: 39rem;
}

.fs--40 {
  font-size: 40rem;
}

.fs--41 {
  font-size: 41rem;
}

.fs--42 {
  font-size: 42rem;
}

.fs--43 {
  font-size: 43rem;
}

.fs--44 {
  font-size: 44rem;
}

.fs--45 {
  font-size: 45rem;
}

.fs--46 {
  font-size: 46rem;
}

.fs--47 {
  font-size: 47rem;
}

.fs--48 {
  font-size: 48rem;
}

.fs--49 {
  font-size: 49rem;
}

.fs--50 {
  font-size: 50rem;
}

.fs--51 {
  font-size: 51rem;
}

.fs--52 {
  font-size: 52rem;
}

.fs--53 {
  font-size: 53rem;
}

.fs--54 {
  font-size: 54rem;
}

.fs--55 {
  font-size: 55rem;
}

.fs--56 {
  font-size: 56rem;
}

.fs--57 {
  font-size: 57rem;
}

.fs--58 {
  font-size: 58rem;
}

.fs--59 {
  font-size: 59rem;
}

.fs--60 {
  font-size: 60rem;
}

.fs--61 {
  font-size: 61rem;
}

.fs--62 {
  font-size: 62rem;
}

.fs--63 {
  font-size: 63rem;
}

.fs--64 {
  font-size: 64rem;
}

.fs--65 {
  font-size: 65rem;
}

.fs--66 {
  font-size: 66rem;
}

.fs--67 {
  font-size: 67rem;
}

.fs--68 {
  font-size: 68rem;
}

.fs--69 {
  font-size: 69rem;
}

.fs--70 {
  font-size: 70rem;
}

.fs--71 {
  font-size: 71rem;
}

.fs--72 {
  font-size: 72rem;
}

.fs--73 {
  font-size: 73rem;
}

.fs--74 {
  font-size: 74rem;
}

.fs--75 {
  font-size: 75rem;
}

.fs--76 {
  font-size: 76rem;
}

.fs--77 {
  font-size: 77rem;
}

.fs--78 {
  font-size: 78rem;
}

.fs--79 {
  font-size: 79rem;
}

.fs--80 {
  font-size: 80rem;
}

.fs--81 {
  font-size: 81rem;
}

.fs--82 {
  font-size: 82rem;
}

.fs--83 {
  font-size: 83rem;
}

.fs--84 {
  font-size: 84rem;
}

.fs--85 {
  font-size: 85rem;
}

.fs--86 {
  font-size: 86rem;
}

.fs--87 {
  font-size: 87rem;
}

.fs--88 {
  font-size: 88rem;
}

.fs--89 {
  font-size: 89rem;
}

.fs--90 {
  font-size: 90rem;
}

.fs--91 {
  font-size: 91rem;
}

.fs--92 {
  font-size: 92rem;
}

.fs--93 {
  font-size: 93rem;
}

.fs--94 {
  font-size: 94rem;
}

.fs--95 {
  font-size: 95rem;
}

.fs--96 {
  font-size: 96rem;
}

.fs--97 {
  font-size: 97rem;
}

.fs--98 {
  font-size: 98rem;
}

.fs--99 {
  font-size: 99rem;
}

.fs--100 {
  font-size: 100rem;
}

.fs--101 {
  font-size: 101rem;
}

.fs--102 {
  font-size: 102rem;
}

.fs--103 {
  font-size: 103rem;
}

.fs--104 {
  font-size: 104rem;
}

.fs--105 {
  font-size: 105rem;
}

.fs--106 {
  font-size: 106rem;
}

.fs--107 {
  font-size: 107rem;
}

.fs--108 {
  font-size: 108rem;
}

.fs--109 {
  font-size: 109rem;
}

.fs--110 {
  font-size: 110rem;
}

.fs--111 {
  font-size: 111rem;
}

.fs--112 {
  font-size: 112rem;
}

.fs--113 {
  font-size: 113rem;
}

.fs--114 {
  font-size: 114rem;
}

.fs--115 {
  font-size: 115rem;
}

.fs--116 {
  font-size: 116rem;
}

.fs--117 {
  font-size: 117rem;
}

.fs--118 {
  font-size: 118rem;
}

.fs--119 {
  font-size: 119rem;
}

.fs--120 {
  font-size: 120rem;
}

.spacer--10 {
  display: block;
  height: 10rem;
  width: 10rem;
}

.spacer--20 {
  display: block;
  height: 20rem;
  width: 20rem;
}

.spacer--30 {
  display: block;
  height: 30rem;
  width: 30rem;
}

.spacer--40 {
  display: block;
  height: 40rem;
  width: 40rem;
}

.spacer--50 {
  display: block;
  height: 50rem;
  width: 50rem;
}

.spacer--60 {
  display: block;
  height: 60rem;
  width: 60rem;
}

.spacer--70 {
  display: block;
  height: 70rem;
  width: 70rem;
}

.spacer--80 {
  display: block;
  height: 80rem;
  width: 80rem;
}

.spacer--90 {
  display: block;
  height: 90rem;
  width: 90rem;
}

.spacer--100 {
  display: block;
  height: 100rem;
  width: 100rem;
}

.size--80 {
  width: 80rem;
  height: 80rem;
  max-width: 80rem;
  max-height: 80rem;
}

.size--90 {
  width: 90rem;
  height: 90rem;
  max-width: 90rem;
  max-height: 90rem;
}

.size--100 {
  width: 100rem;
  height: 100rem;
  max-width: 100rem;
  max-height: 100rem;
}

.size--110 {
  width: 110rem;
  height: 110rem;
  max-width: 110rem;
  max-height: 110rem;
}

.size--120 {
  width: 120rem;
  height: 120rem;
  max-width: 120rem;
  max-height: 120rem;
}

.size--130 {
  width: 130rem;
  height: 130rem;
  max-width: 130rem;
  max-height: 130rem;
}

.size--140 {
  width: 140rem;
  height: 140rem;
  max-width: 140rem;
  max-height: 140rem;
}

.size--150 {
  width: 150rem;
  height: 150rem;
  max-width: 150rem;
  max-height: 150rem;
}

.size--160 {
  width: 160rem;
  height: 160rem;
  max-width: 160rem;
  max-height: 160rem;
}

.size--170 {
  width: 170rem;
  height: 170rem;
  max-width: 170rem;
  max-height: 170rem;
}

.size--180 {
  width: 180rem;
  height: 180rem;
  max-width: 180rem;
  max-height: 180rem;
}

.size--190 {
  width: 190rem;
  height: 190rem;
  max-width: 190rem;
  max-height: 190rem;
}

.size--200 {
  width: 200rem;
  height: 200rem;
  max-width: 200rem;
  max-height: 200rem;
}

.size--210 {
  width: 210rem;
  height: 210rem;
  max-width: 210rem;
  max-height: 210rem;
}

.size--220 {
  width: 220rem;
  height: 220rem;
  max-width: 220rem;
  max-height: 220rem;
}

.size--230 {
  width: 230rem;
  height: 230rem;
  max-width: 230rem;
  max-height: 230rem;
}

.size--240 {
  width: 240rem;
  height: 240rem;
  max-width: 240rem;
  max-height: 240rem;
}

.size--250 {
  width: 250rem;
  height: 250rem;
  max-width: 250rem;
  max-height: 250rem;
}

.size--260 {
  width: 260rem;
  height: 260rem;
  max-width: 260rem;
  max-height: 260rem;
}

.size--270 {
  width: 270rem;
  height: 270rem;
  max-width: 270rem;
  max-height: 270rem;
}

.size--280 {
  width: 280rem;
  height: 280rem;
  max-width: 280rem;
  max-height: 280rem;
}

.size--290 {
  width: 290rem;
  height: 290rem;
  max-width: 290rem;
  max-height: 290rem;
}

.size--300 {
  width: 300rem;
  height: 300rem;
  max-width: 300rem;
  max-height: 300rem;
}

.size--1 {
  width: 1rem;
  height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
}

.size--2 {
  width: 2rem;
  height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
}

.size--3 {
  width: 3rem;
  height: 3rem;
  max-width: 3rem;
  max-height: 3rem;
}

.size--4 {
  width: 4rem;
  height: 4rem;
  max-width: 4rem;
  max-height: 4rem;
}

.size--5 {
  width: 5rem;
  height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
}

.size--6 {
  width: 6rem;
  height: 6rem;
  max-width: 6rem;
  max-height: 6rem;
}

.size--7 {
  width: 7rem;
  height: 7rem;
  max-width: 7rem;
  max-height: 7rem;
}

.size--8 {
  width: 8rem;
  height: 8rem;
  max-width: 8rem;
  max-height: 8rem;
}

.size--9 {
  width: 9rem;
  height: 9rem;
  max-width: 9rem;
  max-height: 9rem;
}

.size--10 {
  width: 10rem;
  height: 10rem;
  max-width: 10rem;
  max-height: 10rem;
}

.size--11 {
  width: 11rem;
  height: 11rem;
  max-width: 11rem;
  max-height: 11rem;
}

.size--12 {
  width: 12rem;
  height: 12rem;
  max-width: 12rem;
  max-height: 12rem;
}

.size--13 {
  width: 13rem;
  height: 13rem;
  max-width: 13rem;
  max-height: 13rem;
}

.size--14 {
  width: 14rem;
  height: 14rem;
  max-width: 14rem;
  max-height: 14rem;
}

.size--15 {
  width: 15rem;
  height: 15rem;
  max-width: 15rem;
  max-height: 15rem;
}

.size--16 {
  width: 16rem;
  height: 16rem;
  max-width: 16rem;
  max-height: 16rem;
}

.size--17 {
  width: 17rem;
  height: 17rem;
  max-width: 17rem;
  max-height: 17rem;
}

.size--18 {
  width: 18rem;
  height: 18rem;
  max-width: 18rem;
  max-height: 18rem;
}

.size--19 {
  width: 19rem;
  height: 19rem;
  max-width: 19rem;
  max-height: 19rem;
}

.size--20 {
  width: 20rem;
  height: 20rem;
  max-width: 20rem;
  max-height: 20rem;
}

.size--21 {
  width: 21rem;
  height: 21rem;
  max-width: 21rem;
  max-height: 21rem;
}

.size--22 {
  width: 22rem;
  height: 22rem;
  max-width: 22rem;
  max-height: 22rem;
}

.size--23 {
  width: 23rem;
  height: 23rem;
  max-width: 23rem;
  max-height: 23rem;
}

.size--24 {
  width: 24rem;
  height: 24rem;
  max-width: 24rem;
  max-height: 24rem;
}

.size--25 {
  width: 25rem;
  height: 25rem;
  max-width: 25rem;
  max-height: 25rem;
}

.size--26 {
  width: 26rem;
  height: 26rem;
  max-width: 26rem;
  max-height: 26rem;
}

.size--27 {
  width: 27rem;
  height: 27rem;
  max-width: 27rem;
  max-height: 27rem;
}

.size--28 {
  width: 28rem;
  height: 28rem;
  max-width: 28rem;
  max-height: 28rem;
}

.size--29 {
  width: 29rem;
  height: 29rem;
  max-width: 29rem;
  max-height: 29rem;
}

.size--30 {
  width: 30rem;
  height: 30rem;
  max-width: 30rem;
  max-height: 30rem;
}

.size--31 {
  width: 31rem;
  height: 31rem;
  max-width: 31rem;
  max-height: 31rem;
}

.size--32 {
  width: 32rem;
  height: 32rem;
  max-width: 32rem;
  max-height: 32rem;
}

.size--33 {
  width: 33rem;
  height: 33rem;
  max-width: 33rem;
  max-height: 33rem;
}

.size--34 {
  width: 34rem;
  height: 34rem;
  max-width: 34rem;
  max-height: 34rem;
}

.size--35 {
  width: 35rem;
  height: 35rem;
  max-width: 35rem;
  max-height: 35rem;
}

.size--36 {
  width: 36rem;
  height: 36rem;
  max-width: 36rem;
  max-height: 36rem;
}

.size--37 {
  width: 37rem;
  height: 37rem;
  max-width: 37rem;
  max-height: 37rem;
}

.size--38 {
  width: 38rem;
  height: 38rem;
  max-width: 38rem;
  max-height: 38rem;
}

.size--39 {
  width: 39rem;
  height: 39rem;
  max-width: 39rem;
  max-height: 39rem;
}

.size--40 {
  width: 40rem;
  height: 40rem;
  max-width: 40rem;
  max-height: 40rem;
}

.size--41 {
  width: 41rem;
  height: 41rem;
  max-width: 41rem;
  max-height: 41rem;
}

.size--42 {
  width: 42rem;
  height: 42rem;
  max-width: 42rem;
  max-height: 42rem;
}

.size--43 {
  width: 43rem;
  height: 43rem;
  max-width: 43rem;
  max-height: 43rem;
}

.size--44 {
  width: 44rem;
  height: 44rem;
  max-width: 44rem;
  max-height: 44rem;
}

.size--45 {
  width: 45rem;
  height: 45rem;
  max-width: 45rem;
  max-height: 45rem;
}

.size--46 {
  width: 46rem;
  height: 46rem;
  max-width: 46rem;
  max-height: 46rem;
}

.size--47 {
  width: 47rem;
  height: 47rem;
  max-width: 47rem;
  max-height: 47rem;
}

.size--48 {
  width: 48rem;
  height: 48rem;
  max-width: 48rem;
  max-height: 48rem;
}

.size--49 {
  width: 49rem;
  height: 49rem;
  max-width: 49rem;
  max-height: 49rem;
}

.size--50 {
  width: 50rem;
  height: 50rem;
  max-width: 50rem;
  max-height: 50rem;
}

.size--51 {
  width: 51rem;
  height: 51rem;
  max-width: 51rem;
  max-height: 51rem;
}

.size--52 {
  width: 52rem;
  height: 52rem;
  max-width: 52rem;
  max-height: 52rem;
}

.size--53 {
  width: 53rem;
  height: 53rem;
  max-width: 53rem;
  max-height: 53rem;
}

.size--54 {
  width: 54rem;
  height: 54rem;
  max-width: 54rem;
  max-height: 54rem;
}

.size--55 {
  width: 55rem;
  height: 55rem;
  max-width: 55rem;
  max-height: 55rem;
}

.size--56 {
  width: 56rem;
  height: 56rem;
  max-width: 56rem;
  max-height: 56rem;
}

.size--57 {
  width: 57rem;
  height: 57rem;
  max-width: 57rem;
  max-height: 57rem;
}

.size--58 {
  width: 58rem;
  height: 58rem;
  max-width: 58rem;
  max-height: 58rem;
}

.size--59 {
  width: 59rem;
  height: 59rem;
  max-width: 59rem;
  max-height: 59rem;
}

.size--60 {
  width: 60rem;
  height: 60rem;
  max-width: 60rem;
  max-height: 60rem;
}

.size--61 {
  width: 61rem;
  height: 61rem;
  max-width: 61rem;
  max-height: 61rem;
}

.size--62 {
  width: 62rem;
  height: 62rem;
  max-width: 62rem;
  max-height: 62rem;
}

.size--63 {
  width: 63rem;
  height: 63rem;
  max-width: 63rem;
  max-height: 63rem;
}

.size--64 {
  width: 64rem;
  height: 64rem;
  max-width: 64rem;
  max-height: 64rem;
}

.size--65 {
  width: 65rem;
  height: 65rem;
  max-width: 65rem;
  max-height: 65rem;
}

.size--66 {
  width: 66rem;
  height: 66rem;
  max-width: 66rem;
  max-height: 66rem;
}

.size--67 {
  width: 67rem;
  height: 67rem;
  max-width: 67rem;
  max-height: 67rem;
}

.size--68 {
  width: 68rem;
  height: 68rem;
  max-width: 68rem;
  max-height: 68rem;
}

.size--69 {
  width: 69rem;
  height: 69rem;
  max-width: 69rem;
  max-height: 69rem;
}

.size--70 {
  width: 70rem;
  height: 70rem;
  max-width: 70rem;
  max-height: 70rem;
}

.size--71 {
  width: 71rem;
  height: 71rem;
  max-width: 71rem;
  max-height: 71rem;
}

.size--72 {
  width: 72rem;
  height: 72rem;
  max-width: 72rem;
  max-height: 72rem;
}

.size--73 {
  width: 73rem;
  height: 73rem;
  max-width: 73rem;
  max-height: 73rem;
}

.size--74 {
  width: 74rem;
  height: 74rem;
  max-width: 74rem;
  max-height: 74rem;
}

.size--75 {
  width: 75rem;
  height: 75rem;
  max-width: 75rem;
  max-height: 75rem;
}

.size--76 {
  width: 76rem;
  height: 76rem;
  max-width: 76rem;
  max-height: 76rem;
}

.size--77 {
  width: 77rem;
  height: 77rem;
  max-width: 77rem;
  max-height: 77rem;
}

.size--78 {
  width: 78rem;
  height: 78rem;
  max-width: 78rem;
  max-height: 78rem;
}

.size--79 {
  width: 79rem;
  height: 79rem;
  max-width: 79rem;
  max-height: 79rem;
}

.size--80 {
  width: 80rem;
  height: 80rem;
  max-width: 80rem;
  max-height: 80rem;
}

.size--81 {
  width: 81rem;
  height: 81rem;
  max-width: 81rem;
  max-height: 81rem;
}

.size--82 {
  width: 82rem;
  height: 82rem;
  max-width: 82rem;
  max-height: 82rem;
}

.size--83 {
  width: 83rem;
  height: 83rem;
  max-width: 83rem;
  max-height: 83rem;
}

.size--84 {
  width: 84rem;
  height: 84rem;
  max-width: 84rem;
  max-height: 84rem;
}

.size--85 {
  width: 85rem;
  height: 85rem;
  max-width: 85rem;
  max-height: 85rem;
}

.size--86 {
  width: 86rem;
  height: 86rem;
  max-width: 86rem;
  max-height: 86rem;
}

.size--87 {
  width: 87rem;
  height: 87rem;
  max-width: 87rem;
  max-height: 87rem;
}

.size--88 {
  width: 88rem;
  height: 88rem;
  max-width: 88rem;
  max-height: 88rem;
}

.size--89 {
  width: 89rem;
  height: 89rem;
  max-width: 89rem;
  max-height: 89rem;
}

.size--90 {
  width: 90rem;
  height: 90rem;
  max-width: 90rem;
  max-height: 90rem;
}

.size--100 {
  width: 100rem;
  height: 100rem;
  max-width: 100rem;
  max-height: 100rem;
}

.size--110 {
  width: 110rem;
  height: 110rem;
  max-width: 110rem;
  max-height: 110rem;
}

.size--120 {
  width: 120rem;
  height: 120rem;
  max-width: 120rem;
  max-height: 120rem;
}

.size--130 {
  width: 130rem;
  height: 130rem;
  max-width: 130rem;
  max-height: 130rem;
}

.size--140 {
  width: 140rem;
  height: 140rem;
  max-width: 140rem;
  max-height: 140rem;
}

.size--150 {
  width: 150rem;
  height: 150rem;
  max-width: 150rem;
  max-height: 150rem;
}

.size--160 {
  width: 160rem;
  height: 160rem;
  max-width: 160rem;
  max-height: 160rem;
}

.size--170 {
  width: 170rem;
  height: 170rem;
  max-width: 170rem;
  max-height: 170rem;
}

.size--180 {
  width: 180rem;
  height: 180rem;
  max-width: 180rem;
  max-height: 180rem;
}

.size--190 {
  width: 190rem;
  height: 190rem;
  max-width: 190rem;
  max-height: 190rem;
}

.size--200 {
  width: 200rem;
  height: 200rem;
  max-width: 200rem;
  max-height: 200rem;
}

.size--210 {
  width: 210rem;
  height: 210rem;
  max-width: 210rem;
  max-height: 210rem;
}

.size--220 {
  width: 220rem;
  height: 220rem;
  max-width: 220rem;
  max-height: 220rem;
}

.size--230 {
  width: 230rem;
  height: 230rem;
  max-width: 230rem;
  max-height: 230rem;
}

.size--240 {
  width: 240rem;
  height: 240rem;
  max-width: 240rem;
  max-height: 240rem;
}

.size--250 {
  width: 250rem;
  height: 250rem;
  max-width: 250rem;
  max-height: 250rem;
}

.size--260 {
  width: 260rem;
  height: 260rem;
  max-width: 260rem;
  max-height: 260rem;
}

.size--270 {
  width: 270rem;
  height: 270rem;
  max-width: 270rem;
  max-height: 270rem;
}

.size--280 {
  width: 280rem;
  height: 280rem;
  max-width: 280rem;
  max-height: 280rem;
}

.size--290 {
  width: 290rem;
  height: 290rem;
  max-width: 290rem;
  max-height: 290rem;
}

.size--300 {
  width: 300rem;
  height: 300rem;
  max-width: 300rem;
  max-height: 300rem;
}

.size--310 {
  width: 310rem;
  height: 310rem;
  max-width: 310rem;
  max-height: 310rem;
}

.size--320 {
  width: 320rem;
  height: 320rem;
  max-width: 320rem;
  max-height: 320rem;
}

.size--330 {
  width: 330rem;
  height: 330rem;
  max-width: 330rem;
  max-height: 330rem;
}

.size--340 {
  width: 340rem;
  height: 340rem;
  max-width: 340rem;
  max-height: 340rem;
}

.size--350 {
  width: 350rem;
  height: 350rem;
  max-width: 350rem;
  max-height: 350rem;
}

.size--360 {
  width: 360rem;
  height: 360rem;
  max-width: 360rem;
  max-height: 360rem;
}

.size--370 {
  width: 370rem;
  height: 370rem;
  max-width: 370rem;
  max-height: 370rem;
}

.size--380 {
  width: 380rem;
  height: 380rem;
  max-width: 380rem;
  max-height: 380rem;
}

.size--390 {
  width: 390rem;
  height: 390rem;
  max-width: 390rem;
  max-height: 390rem;
}

.size--400 {
  width: 400rem;
  height: 400rem;
  max-width: 400rem;
  max-height: 400rem;
}

.size--410 {
  width: 410rem;
  height: 410rem;
  max-width: 410rem;
  max-height: 410rem;
}

.size--420 {
  width: 420rem;
  height: 420rem;
  max-width: 420rem;
  max-height: 420rem;
}

.size--430 {
  width: 430rem;
  height: 430rem;
  max-width: 430rem;
  max-height: 430rem;
}

.size--440 {
  width: 440rem;
  height: 440rem;
  max-width: 440rem;
  max-height: 440rem;
}

.size--450 {
  width: 450rem;
  height: 450rem;
  max-width: 450rem;
  max-height: 450rem;
}

.size--460 {
  width: 460rem;
  height: 460rem;
  max-width: 460rem;
  max-height: 460rem;
}

.size--470 {
  width: 470rem;
  height: 470rem;
  max-width: 470rem;
  max-height: 470rem;
}

.size--480 {
  width: 480rem;
  height: 480rem;
  max-width: 480rem;
  max-height: 480rem;
}

.size--490 {
  width: 490rem;
  height: 490rem;
  max-width: 490rem;
  max-height: 490rem;
}

.size--500 {
  width: 500rem;
  height: 500rem;
  max-width: 500rem;
  max-height: 500rem;
}

.size--510 {
  width: 510rem;
  height: 510rem;
  max-width: 510rem;
  max-height: 510rem;
}

.size--520 {
  width: 520rem;
  height: 520rem;
  max-width: 520rem;
  max-height: 520rem;
}

.size--530 {
  width: 530rem;
  height: 530rem;
  max-width: 530rem;
  max-height: 530rem;
}

.size--540 {
  width: 540rem;
  height: 540rem;
  max-width: 540rem;
  max-height: 540rem;
}

.size--550 {
  width: 550rem;
  height: 550rem;
  max-width: 550rem;
  max-height: 550rem;
}

.size--560 {
  width: 560rem;
  height: 560rem;
  max-width: 560rem;
  max-height: 560rem;
}

.size--570 {
  width: 570rem;
  height: 570rem;
  max-width: 570rem;
  max-height: 570rem;
}

.size--580 {
  width: 580rem;
  height: 580rem;
  max-width: 580rem;
  max-height: 580rem;
}

.size--590 {
  width: 590rem;
  height: 590rem;
  max-width: 590rem;
  max-height: 590rem;
}

.size--600 {
  width: 600rem;
  height: 600rem;
  max-width: 600rem;
  max-height: 600rem;
}

.size--610 {
  width: 610rem;
  height: 610rem;
  max-width: 610rem;
  max-height: 610rem;
}

.size--620 {
  width: 620rem;
  height: 620rem;
  max-width: 620rem;
  max-height: 620rem;
}

.size--630 {
  width: 630rem;
  height: 630rem;
  max-width: 630rem;
  max-height: 630rem;
}

.size--640 {
  width: 640rem;
  height: 640rem;
  max-width: 640rem;
  max-height: 640rem;
}

.size--650 {
  width: 650rem;
  height: 650rem;
  max-width: 650rem;
  max-height: 650rem;
}

.size--660 {
  width: 660rem;
  height: 660rem;
  max-width: 660rem;
  max-height: 660rem;
}

.size--670 {
  width: 670rem;
  height: 670rem;
  max-width: 670rem;
  max-height: 670rem;
}

.size--680 {
  width: 680rem;
  height: 680rem;
  max-width: 680rem;
  max-height: 680rem;
}

.size--690 {
  width: 690rem;
  height: 690rem;
  max-width: 690rem;
  max-height: 690rem;
}

.size--700 {
  width: 700rem;
  height: 700rem;
  max-width: 700rem;
  max-height: 700rem;
}

.size--710 {
  width: 710rem;
  height: 710rem;
  max-width: 710rem;
  max-height: 710rem;
}

.size--720 {
  width: 720rem;
  height: 720rem;
  max-width: 720rem;
  max-height: 720rem;
}

.size--730 {
  width: 730rem;
  height: 730rem;
  max-width: 730rem;
  max-height: 730rem;
}

.size--740 {
  width: 740rem;
  height: 740rem;
  max-width: 740rem;
  max-height: 740rem;
}

.size--750 {
  width: 750rem;
  height: 750rem;
  max-width: 750rem;
  max-height: 750rem;
}

.size--760 {
  width: 760rem;
  height: 760rem;
  max-width: 760rem;
  max-height: 760rem;
}

.size--770 {
  width: 770rem;
  height: 770rem;
  max-width: 770rem;
  max-height: 770rem;
}

.size--780 {
  width: 780rem;
  height: 780rem;
  max-width: 780rem;
  max-height: 780rem;
}

.size--790 {
  width: 790rem;
  height: 790rem;
  max-width: 790rem;
  max-height: 790rem;
}

.size--800 {
  width: 800rem;
  height: 800rem;
  max-width: 800rem;
  max-height: 800rem;
}

.size--810 {
  width: 810rem;
  height: 810rem;
  max-width: 810rem;
  max-height: 810rem;
}

.size--820 {
  width: 820rem;
  height: 820rem;
  max-width: 820rem;
  max-height: 820rem;
}

.size--830 {
  width: 830rem;
  height: 830rem;
  max-width: 830rem;
  max-height: 830rem;
}

.size--840 {
  width: 840rem;
  height: 840rem;
  max-width: 840rem;
  max-height: 840rem;
}

.size--850 {
  width: 850rem;
  height: 850rem;
  max-width: 850rem;
  max-height: 850rem;
}

.size--860 {
  width: 860rem;
  height: 860rem;
  max-width: 860rem;
  max-height: 860rem;
}

.size--870 {
  width: 870rem;
  height: 870rem;
  max-width: 870rem;
  max-height: 870rem;
}

.size--880 {
  width: 880rem;
  height: 880rem;
  max-width: 880rem;
  max-height: 880rem;
}

.size--890 {
  width: 890rem;
  height: 890rem;
  max-width: 890rem;
  max-height: 890rem;
}

.size--900 {
  width: 900rem;
  height: 900rem;
  max-width: 900rem;
  max-height: 900rem;
}

.size--910 {
  width: 910rem;
  height: 910rem;
  max-width: 910rem;
  max-height: 910rem;
}

.size--920 {
  width: 920rem;
  height: 920rem;
  max-width: 920rem;
  max-height: 920rem;
}

.size--930 {
  width: 930rem;
  height: 930rem;
  max-width: 930rem;
  max-height: 930rem;
}

.size--940 {
  width: 940rem;
  height: 940rem;
  max-width: 940rem;
  max-height: 940rem;
}

.size--950 {
  width: 950rem;
  height: 950rem;
  max-width: 950rem;
  max-height: 950rem;
}

.size--960 {
  width: 960rem;
  height: 960rem;
  max-width: 960rem;
  max-height: 960rem;
}

.size--970 {
  width: 970rem;
  height: 970rem;
  max-width: 970rem;
  max-height: 970rem;
}

.size--980 {
  width: 980rem;
  height: 980rem;
  max-width: 980rem;
  max-height: 980rem;
}

.size--990 {
  width: 990rem;
  height: 990rem;
  max-width: 990rem;
  max-height: 990rem;
}

.size--1000 {
  width: 1000rem;
  height: 1000rem;
  max-width: 1000rem;
  max-height: 1000rem;
}

.ml--0 {
  margin-left: 0rem;
}

.mr--0 {
  margin-right: 0rem;
}

.mt--0 {
  margin-top: 0rem;
}

.mb--0 {
  margin-bottom: 0rem;
}

.m--0 {
  margin: 0rem;
}

.ml--10 {
  margin-left: 10rem;
}

.mr--10 {
  margin-right: 10rem;
}

.mt--10 {
  margin-top: 10rem;
}

.mb--10 {
  margin-bottom: 10rem;
}

.m--10 {
  margin: 10rem;
}

.ml--20 {
  margin-left: 20rem;
}

.mr--20 {
  margin-right: 20rem;
}

.mt--20 {
  margin-top: 20rem;
}

.mb--20 {
  margin-bottom: 20rem;
}

.m--20 {
  margin: 20rem;
}

.ml--30 {
  margin-left: 30rem;
}

.mr--30 {
  margin-right: 30rem;
}

.mt--30 {
  margin-top: 30rem;
}

.mb--30 {
  margin-bottom: 30rem;
}

.m--30 {
  margin: 30rem;
}

.ml--40 {
  margin-left: 40rem;
}

.mr--40 {
  margin-right: 40rem;
}

.mt--40 {
  margin-top: 40rem;
}

.mb--40 {
  margin-bottom: 40rem;
}

.m--40 {
  margin: 40rem;
}

.ml--50 {
  margin-left: 50rem;
}

.mr--50 {
  margin-right: 50rem;
}

.mt--50 {
  margin-top: 50rem;
}

.mb--50 {
  margin-bottom: 50rem;
}

.m--50 {
  margin: 50rem;
}

.ml--60 {
  margin-left: 60rem;
}

.mr--60 {
  margin-right: 60rem;
}

.mt--60 {
  margin-top: 60rem;
}

.mb--60 {
  margin-bottom: 60rem;
}

.m--60 {
  margin: 60rem;
}

.ml--70 {
  margin-left: 70rem;
}

.mr--70 {
  margin-right: 70rem;
}

.mt--70 {
  margin-top: 70rem;
}

.mb--70 {
  margin-bottom: 70rem;
}

.m--70 {
  margin: 70rem;
}

.ml--80 {
  margin-left: 80rem;
}

.mr--80 {
  margin-right: 80rem;
}

.mt--80 {
  margin-top: 80rem;
}

.mb--80 {
  margin-bottom: 80rem;
}

.m--80 {
  margin: 80rem;
}

.ml--90 {
  margin-left: 90rem;
}

.mr--90 {
  margin-right: 90rem;
}

.mt--90 {
  margin-top: 90rem;
}

.mb--90 {
  margin-bottom: 90rem;
}

.m--90 {
  margin: 90rem;
}

.ml--100 {
  margin-left: 100rem;
}

.mr--100 {
  margin-right: 100rem;
}

.mt--100 {
  margin-top: 100rem;
}

.mb--100 {
  margin-bottom: 100rem;
}

.m--100 {
  margin: 100rem;
}

.ml--110 {
  margin-left: 110rem;
}

.mr--110 {
  margin-right: 110rem;
}

.mt--110 {
  margin-top: 110rem;
}

.mb--110 {
  margin-bottom: 110rem;
}

.m--110 {
  margin: 110rem;
}

.ml--120 {
  margin-left: 120rem;
}

.mr--120 {
  margin-right: 120rem;
}

.mt--120 {
  margin-top: 120rem;
}

.mb--120 {
  margin-bottom: 120rem;
}

.m--120 {
  margin: 120rem;
}

.ml--130 {
  margin-left: 130rem;
}

.mr--130 {
  margin-right: 130rem;
}

.mt--130 {
  margin-top: 130rem;
}

.mb--130 {
  margin-bottom: 130rem;
}

.m--130 {
  margin: 130rem;
}

.ml--140 {
  margin-left: 140rem;
}

.mr--140 {
  margin-right: 140rem;
}

.mt--140 {
  margin-top: 140rem;
}

.mb--140 {
  margin-bottom: 140rem;
}

.m--140 {
  margin: 140rem;
}

.ml--150 {
  margin-left: 150rem;
}

.mr--150 {
  margin-right: 150rem;
}

.mt--150 {
  margin-top: 150rem;
}

.mb--150 {
  margin-bottom: 150rem;
}

.m--150 {
  margin: 150rem;
}

.pl--0 {
  padding-left: 0rem;
}

.pr--0 {
  padding-right: 0rem;
}

.pt--0 {
  padding-top: 0rem;
}

.pb--0 {
  padding-bottom: 0rem;
}

.p--0 {
  padding: 0rem;
}

.pl--10 {
  padding-left: 10rem;
}

.pr--10 {
  padding-right: 10rem;
}

.pt--10 {
  padding-top: 10rem;
}

.pb--10 {
  padding-bottom: 10rem;
}

.p--10 {
  padding: 10rem;
}

.pl--20 {
  padding-left: 20rem;
}

.pr--20 {
  padding-right: 20rem;
}

.pt--20 {
  padding-top: 20rem;
}

.pb--20 {
  padding-bottom: 20rem;
}

.p--20 {
  padding: 20rem;
}

.pl--30 {
  padding-left: 30rem;
}

.pr--30 {
  padding-right: 30rem;
}

.pt--30 {
  padding-top: 30rem;
}

.pb--30 {
  padding-bottom: 30rem;
}

.p--30 {
  padding: 30rem;
}

.pl--40 {
  padding-left: 40rem;
}

.pr--40 {
  padding-right: 40rem;
}

.pt--40 {
  padding-top: 40rem;
}

.pb--40 {
  padding-bottom: 40rem;
}

.p--40 {
  padding: 40rem;
}

.pl--50 {
  padding-left: 50rem;
}

.pr--50 {
  padding-right: 50rem;
}

.pt--50 {
  padding-top: 50rem;
}

.pb--50 {
  padding-bottom: 50rem;
}

.p--50 {
  padding: 50rem;
}

.pl--60 {
  padding-left: 60rem;
}

.pr--60 {
  padding-right: 60rem;
}

.pt--60 {
  padding-top: 60rem;
}

.pb--60 {
  padding-bottom: 60rem;
}

.p--60 {
  padding: 60rem;
}

.pl--70 {
  padding-left: 70rem;
}

.pr--70 {
  padding-right: 70rem;
}

.pt--70 {
  padding-top: 70rem;
}

.pb--70 {
  padding-bottom: 70rem;
}

.p--70 {
  padding: 70rem;
}

.pl--80 {
  padding-left: 80rem;
}

.pr--80 {
  padding-right: 80rem;
}

.pt--80 {
  padding-top: 80rem;
}

.pb--80 {
  padding-bottom: 80rem;
}

.p--80 {
  padding: 80rem;
}

.pl--90 {
  padding-left: 90rem;
}

.pr--90 {
  padding-right: 90rem;
}

.pt--90 {
  padding-top: 90rem;
}

.pb--90 {
  padding-bottom: 90rem;
}

.p--90 {
  padding: 90rem;
}

.pl--100 {
  padding-left: 100rem;
}

.pr--100 {
  padding-right: 100rem;
}

.pt--100 {
  padding-top: 100rem;
}

.pb--100 {
  padding-bottom: 100rem;
}

.p--100 {
  padding: 100rem;
}

.pl--110 {
  padding-left: 110rem;
}

.pr--110 {
  padding-right: 110rem;
}

.pt--110 {
  padding-top: 110rem;
}

.pb--110 {
  padding-bottom: 110rem;
}

.p--110 {
  padding: 110rem;
}

.pl--120 {
  padding-left: 120rem;
}

.pr--120 {
  padding-right: 120rem;
}

.pt--120 {
  padding-top: 120rem;
}

.pb--120 {
  padding-bottom: 120rem;
}

.p--120 {
  padding: 120rem;
}

.pl--130 {
  padding-left: 130rem;
}

.pr--130 {
  padding-right: 130rem;
}

.pt--130 {
  padding-top: 130rem;
}

.pb--130 {
  padding-bottom: 130rem;
}

.p--130 {
  padding: 130rem;
}

.pl--140 {
  padding-left: 140rem;
}

.pr--140 {
  padding-right: 140rem;
}

.pt--140 {
  padding-top: 140rem;
}

.pb--140 {
  padding-bottom: 140rem;
}

.p--140 {
  padding: 140rem;
}

.p0 {
  padding: 0;
}

.m0 {
  margin: 0;
}

.m--auto {
  margin: auto;
}

.mt--auto {
  margin-top: auto;
}

.mb--auto {
  margin-bottom: auto;
}

.unstyled-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.gap--0 {
  gap: 0rem;
}

.gap--5 {
  gap: 5rem;
}

.gap--10 {
  gap: 10rem;
}

.gap--15 {
  gap: 15rem;
}

.gap--20 {
  gap: 20rem;
}

.gap--25 {
  gap: 25rem;
}

.gap--30 {
  gap: 30rem;
}

.gap--35 {
  gap: 35rem;
}

.gap--40 {
  gap: 40rem;
}

.gap--45 {
  gap: 45rem;
}

.gap--50 {
  gap: 50rem;
}

.gap--55 {
  gap: 55rem;
}

.gap--60 {
  gap: 60rem;
}

.gap--65 {
  gap: 65rem;
}

.gap--70 {
  gap: 70rem;
}

.gap--75 {
  gap: 75rem;
}

.gap--80 {
  gap: 80rem;
}

.gap--85 {
  gap: 85rem;
}

.gap--90 {
  gap: 90rem;
}

.gap--95 {
  gap: 95rem;
}

.gap--100 {
  gap: 100rem;
}

/*
#
###
##### SCROLLBAR STYLES
###
#

*/
::-webkit-scrollbar {
  width: 10rem;
  height: 10rem;
  background: rgba(0, 0, 0, 0);
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5rem;
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5rem;
  background-color: var(--accent);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 5rem;
  background-color: var(--accent);
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

html.touchlessEnabled .hideScrollbar {
  touch-action: none;
}

.eventsNone {
  pointer-events: none;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.cols--1 {
  grid-template-columns: 1fr;
}

.cols--2 {
  grid-template-columns: repeat(2, 1fr);
}

.cols--3 {
  grid-template-columns: repeat(3, 1fr);
}

.cols--4 {
  grid-template-columns: repeat(4, 1fr);
}

.top3 {
  grid-template-columns: repeat(2, 1fr);
}
.top3 > :last-child {
  grid-column: 1/-1;
}

.color-picker-btn {
  font-size: 20rem !important;
  min-width: 80rem;
  background-color: rgb(66, 149, 226) !important;
  border-radius: 10rem;
  padding: 5rem 0;
  color: white !important;
  margin-top: 20rem !important;
  margin-right: 10rem !important;
}

.editor-edit-tag {
  position: absolute;
  background: rgb(196, 73, 198);
  top: 0;
  right: 10rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 15rem;
  color: white;
  text-transform: uppercase;
  right: 0;
  width: 50rem;
  height: 50rem;
  border-radius: 50%;
  transform: translate(50%, -50%);
  border-bottom-left-radius: 2rem;
  text-align: center;
  font-size: 18rem;
  cursor: context-menu;
  filter: drop-shadow(0 0 4rem rgba(85, 34, 34, 0.17));
  filter: var(--shadow-filter);
}

html.ada {
  font-size: 0.04vh;
}
html.ada .logo {
  max-width: 150rem;
}
html.ada .page-container,
html.ada .page-container--grid {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50vh;
}
html.ada .page-header {
  padding: 24rem 0;
}
html.ada .popup__page-container {
  position: static;
  height: 100%;
}
html.ada .popup__content {
  position: absolute;
  bottom: 50rem;
  max-height: 45vh;
  max-width: 75vw;
}
html.ada .welcome .title {
  font-size: 100rem;
}
html.ada .welcome .subtitle {
  font-size: 54rem;
}
html.ada .footer__cart {
  height: 224rem;
}
html.ada .footer__left {
  justify-content: center;
}
html.ada .buttons-list .products-grid {
  padding: 10rem 40rem;
  grid-template-rows: minmax(300rem, 1fr);
  grid-template-columns: repeat(4, 1fr);
}
html.ada .buttons-list .product img {
  max-width: 120rem;
}
html.ada .product {
  transition: none;
}
html.ada .product__title {
  font-size: 24rem;
}
html.ada .modifiers-list {
  grid-template-rows: 70rem auto;
}
html.ada .modifiers-list.withoutHeader {
  grid-template-rows: unset;
}
html.ada .nav-slider__wrapper {
  height: 100%;
}
html.ada .nav-slider .nav-slider-item__title {
  font-size: 24rem;
  transition: none;
}
html.ada .circle {
  width: 300rem;
  height: 300rem;
}
html.ada .circle.small {
  width: 250rem;
  height: 250rem;
}
html.ada .quantity-info__actions {
  padding: 8rem 0;
}
html.ada .icon-pos--error {
  max-height: 400rem;
}

/**
 * ----------------------------------------
 * animation fadeIn
 * ----------------------------------------
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInFwd {
  0% {
    opacity: 0;
    transform: translateZ(-100rem);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes handAnimate {
  from {
    transform: translateY(60%);
  }
}
@keyframes bounceInFwd {
  0% {
    transform: scale(0);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  30% {
    transform: scale(0.3);
    animation-timing-function: ease-in;
  }
  50% {
    transform: scale(0.5);
    animation-timing-function: ease-in;
  }
  70% {
    transform: scale(0.7);
    animation-timing-function: ease-in;
  }
  80% {
    transform: scale(0.8);
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
@keyframes slideInBottom {
  0% {
    transform: translateY(1000rem);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideInBottomBack {
  0% {
    transform: translateY(1000rem);
    opacity: 0.8;
  }
  80% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    transform: translateY(50rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-1000rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(1000rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes scaleInHorCenter {
  0% {
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}
@keyframes scaleInCenter {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.bounceDown {
  animation: bounceDown 1.4s infinite linear;
}

@keyframes bounceDown {
  0% {
    transform: translateY(20rem);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20rem);
  }
}
.bounceUp {
  animation: bounceUp 1.4s infinite linear;
}

@keyframes bounceUp {
  0% {
    transform: translateY(-20rem);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20rem);
  }
}
.rotateCenter {
  animation: rotateCenter 2s infinite both;
}

@keyframes rotateCenter {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes keyStroke {
  0% {
    transform: scale(0.9);
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-in;
  }
}
@keyframes ripple {
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}
.payment-logos {
  position: absolute;
  left: 0;
  bottom: 50rem;
  padding: 0 20rem;
  pointer-events: none;
  z-index: 1;
}

.payment-logos__image {
  margin: 0 20rem;
  max-width: 80rem;
}

.slide-show {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.slide-show__item {
  object-fit: cover;
  width: 100%;
}

.slide-show__item--animated {
  animation: fadeIn 700ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.ada-banners {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.allergen-selection__content {
  padding: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  max-height: 95%;
  border-radius: 20rem;
  background: var(--primary);
}

.allergen-selection__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  list-style-type: none;
  max-height: 60vh;
  width: 80%;
  overflow-y: auto;
  padding: 40rem 20rem;
  margin: 40rem auto;
}

.allergen-selection__allergen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2rem solid var(--secondary);
  padding: 12rem 20rem;
  width: 100%;
  border-bottom-left-radius: 20rem;
  border-top-right-radius: 20rem;
  transition: all 250ms ease-in-out;
}

.allergen-selection__allergen--icon {
  width: 54rem;
  height: 54rem;
}

.allergen-selection__allergen--title {
  line-height: 1;
  padding: 0 14rem;
  font-size: 22rem;
  color: var(--secondary);
}

.allergen-selection__allergen--selected {
  background: var(--accent);
  border: 2rem solid var(--accent);
}

.allergen-selection__allergen--selected > * {
  color: var(--primary);
}

.basket-suggestions {
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20rem 40rem;
  min-height: 200rem;
}

.basket-suggestions__wrapper {
  display: flex;
  gap: 30rem;
  flex: 1;
}

.basket-suggestions__img {
  max-width: 180rem;
}

.basket-suggestions__title {
  color: var(--light-gray);
  padding: 10rem 0;
}

.basket-suggestions__button-title {
  padding: 4rem 0;
}

.basket-suggestions__button-price {
  color: var(--light-gray);
}

.basket-toggler {
  width: 210rem;
  height: 190rem;
  background: var(--secondary);
  filter: var(--shadow-filter);
  border-top-left-radius: 30rem;
  border-top-right-radius: 30rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.basket-toggler--opened {
  top: 15vh;
  z-index: 11;
  border-radius: 30rem;
}

.basket-toggler__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 36rem;
  position: relative;
}

.basket-toggler__items {
  display: flex;
  flex-direction: column;
  width: 70rem;
  padding-left: 12rem;
}

.basket-toggler__items--text {
  font-weight: normal;
  font-size: 24rem;
  font-family: var(--fontSecondary);
  text-align: left;
}

.basket-toggler__line {
  height: 8rem;
  border-radius: 8rem;
  background: var(--accent);
  margin: 6rem auto;
  width: 150rem;
}

.basket-toggler__items-number {
  position: absolute;
  right: 38rem;
  top: 28rem;
  width: 60rem;
  height: 60rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--accent);
}

.basket-toggler__icon-chevron {
  position: absolute;
  top: 15rem;
  left: 50%;
  transform: translateX(-50%);
}

.basket-toggler__icon-chevron--opened {
  transform: rotate(180deg) translateX(50%);
  transition: all 0.35s ease-in-out;
}

.ada .basket-toggler--opened {
  top: 20rem;
}

.rtl .basket-toggler__items-number {
  left: 38rem;
  right: unset;
}

.basket {
  align-items: flex-end;
  height: 76vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 300rem;
  z-index: 1;
}

.basket__content {
  background: var(--basketBackgroundColor);
  border-radius: unset;
  padding: 180rem 0 0 0;
}

.basket__header {
  min-height: 120rem;
}

.basket__header--button {
  position: absolute;
  left: 46rem;
  top: 50rem;
}

.basket__total {
  padding: 10rem 30rem;
}

.ada .basket {
  top: initial;
  height: calc(50vh - var(--footer-height));
  bottom: var(--footer-height);
}

.rtl .basket__header--button {
  right: 46rem;
  left: unset;
}

.button__info-button {
  position: absolute;
  top: 10rem;
  right: 0;
}

.rtl .button .product__title {
  padding-left: 20rem;
  padding-right: unset;
  text-align: right;
}

.rtl .button__info-button {
  left: 0;
  right: unset;
  margin-left: 10rem;
  margin-right: unset;
}

.button-details__container {
  overflow-y: auto;
}

.button-details__header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.button-details__header--product {
  --product-border-radius: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: normal;
  width: 300rem;
  margin: 10rem auto;
  border-radius: var(--product-border-radius);
  padding: 25rem;
  filter: var(--shadow-filter);
  color: var(--tertiary);
  background-color: var(--secondary);
  position: relative;
}

.button-details__header--product-title {
  width: 100%;
  text-align: left;
  font-size: 32rem;
  line-height: 1;
  padding-right: 28rem;
}

.button-details__header--product-img {
  max-width: 200rem;
  margin: 10rem auto;
}

.button-details__header--info {
  position: absolute;
  top: 10px;
  right: 0;
}

.rtl .button-details__header--info {
  left: 0;
  right: unset;
  margin-left: 10rem;
  margin-right: 0;
}

.rtl .button-details__header--product-title {
  text-align: right;
  padding-left: 28rem;
  padding-right: unset;
}

.rtl .button-item__modifier-checkmark {
  left: -20rem;
  right: unset;
}

.rtl .button-modifier--modifier-checkmark {
  left: -20rem;
  right: unset;
}

.button-modifier-list__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button-modifier-list__header--title {
  margin: 0;
  min-height: 36rem;
}

.buttons-counter-edit__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20rem 0;
}

.buttons-counter-edit__actions--amount {
  width: 80rem;
  text-align: center;
}

.buttons-counter-edit__actions--plus {
  width: 52rem;
  height: 52rem;
}

.buttons-counter-edit__actions--button:disabled {
  opacity: 0.7;
}

.basket-product-list {
  padding: 0 30rem;
}

.basket-product-list__product-card:last-child {
  padding-bottom: 30rem;
}

.calories__actions {
  position: relative;
  padding-bottom: 50rem;
}

.calories__pagination {
  position: absolute;
  right: 0;
}

.calories__pagination--number {
  font-size: 40rem;
  width: 60rem;
  color: var(--secondary);
  text-align: center;
}

.collapsible {
  padding: 10rem 30rem;
}

.collapsible__content {
  overflow: hidden;
  background: var(--secondary);
  margin: 8rem 0;
}

.collapsible__title {
  font-size: 30rem;
  position: relative;
}

.collapsible__toggle {
  font-size: 30rem;
  width: 100%;
  padding: 20rem;
  filter: var(--shadow-filter);
  background: var(--secondary);
  border-bottom-left-radius: 20rem;
  border-bottom-right-radius: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapsible__toggle--arrow {
  position: absolute;
  top: 10rem;
  right: -36rem;
}

.combo-stepper__content {
  height: 95%;
}

.combo-stepper__container {
  overflow-y: unset;
}

.combo-stepper__container--actions {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 200rem;
  padding: 0 30rem;
  border-bottom-left-radius: 18rem;
  border-bottom-right-radius: 18rem;
  background: var(--popupsFooterBackgroundColor, var(--primary));
  justify-content: space-between;
}

.combo-stepper__top-menu {
  display: flex;
  align-items: center;
  min-height: 120rem;
  padding: 0 40rem;
  position: relative;
}

.combo-stepper__top-menu--title {
  position: absolute;
  font-size: 40rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.ada .combo-stepper__content {
  max-width: 95%;
  height: 45vh;
}

.ada .combo-stepper .product-stepper-header {
  min-height: 400rem;
  background-position: bottom;
}

.ada .combo-stepper .product-stepper-header__product-img {
  max-width: 200rem;
  top: 40rem;
}

.confirm-dialog__content {
  padding: 120rem 30rem;
}

.confirm-dialog__title {
  font-size: 58rem;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.confirm-dialog__actions {
  margin-top: 60rem;
  background: none;
  min-height: unset;
  justify-content: center;
  gap: 100rem;
}

.confirm-dialog__actions--button {
  min-width: 340rem;
  height: 84rem;
  font-size: 34rem;
}

.dropdown {
  grid-column: 1/-1;
  height: 320rem;
  width: 100%;
  margin: 0 auto;
  padding: 20rem;
  background: var(--background-gray);
  box-shadow: inset 0 0 16rem 5rem rgba(0, 0, 0, 0.16);
}

.dropdown__modifier {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.dropdown__modifier--disabled {
  opacity: 0.3;
  filter: grayscale(100%);
  pointer-events: none;
}

.dropdown__modifier--image {
  max-width: 150rem;
}

.dropdown__modifier--button {
  font-size: 22rem;
  padding: 8rem 30rem;
  min-height: 52rem;
}

.ada .dropdown__modifiers {
  height: 100%;
}

.ada .dropdown__modifier--image {
  max-width: 100rem;
}

.edit-single-combo-step__content {
  height: 95%;
}

.edit-single-combo-step__page-container {
  padding: 0;
  overflow-y: unset;
}

.edit-single-combo-step__top-menu {
  display: flex;
  align-items: center;
  min-height: 120rem;
  padding: 0 40rem;
  position: relative;
}

.edit-single-combo-step__top-menu--title {
  position: absolute;
  font-size: 40rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.edit-single-combo-step__actions {
  gap: 10rem;
  min-height: 200rem;
}

.ada .edit-single-combo-step__content {
  max-width: 95%;
  height: 45vh;
}

.ada .edit-single-combo-step .product-stepper-header {
  min-height: 400rem;
  background-position: bottom;
}

.ada .edit-single-combo-step .product-stepper-header__product-img {
  max-width: 200rem;
  top: 40rem;
}

.edit-single-modifier-step__content {
  height: 95%;
}

.edit-single-modifier-step__page-container {
  padding: 0;
  overflow-y: unset;
}

.edit-single-modifier-step__top-menu {
  display: flex;
  align-items: center;
  min-height: 120rem;
  padding: 0 40rem;
  position: relative;
}

.edit-single-modifier-step__top-menu--title {
  position: absolute;
  font-size: 40rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.edit-single-modifier-step__actions {
  gap: 10rem;
  min-height: 200rem;
}

.ada .edit-single-modifier-step__content {
  max-width: 95%;
  height: 45vh;
}

.ada .edit-single-modifier-step .product-stepper-header {
  min-height: 400rem;
  background-position: bottom;
}

.ada .edit-single-modifier-step .product-stepper-header__product-img {
  max-width: 200rem;
  top: 40rem;
}

.footer {
  height: var(--footer-height);
  width: 100%;
  background: var(--footerLightBackgroundColor);
  display: grid;
  grid-template-columns: 2fr 210rem 2fr;
  align-items: center;
  justify-items: center;
  position: relative;
}

.footer__left {
  padding-left: 80rem;
}

.footer__ada-button {
  position: absolute;
  left: 20rem;
  bottom: 0;
  top: 0;
}

.footer__ada-button-image {
  margin: 0;
}

.footer__ada-button-image--light {
  display: none;
}

.rtl .footer__left {
  padding-right: 80rem;
  padding-left: unset;
}

.rtl .footer__ada-button {
  right: 20rem;
  left: unset;
}

.ice-modifier {
  padding: 50rem;
  gap: 50rem;
  justify-content: center;
}

.ice-modifier__product {
  --product-border-radius: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: normal;
  width: 300rem;
  margin: 10rem 0;
  border-radius: var(--product-border-radius);
  padding: 25rem;
  filter: var(--shadow-filter);
  color: var(--tertiary);
  background-color: var(--secondary);
  position: relative;
}

.ice-modifier__product--selected {
  box-shadow: inset 0 0 0 5rem var(--accent);
  filter: none;
}

.ice-modifier__product--selected .ice-modifier__product-title--checkmark {
  display: block;
}

.ice-modifier__product-title {
  width: 100%;
  text-align: center;
  font-size: 32rem;
  line-height: 1;
}

.ice-modifier__product-title--checkmark {
  display: none;
  position: absolute;
  top: -30rem;
  right: -20rem;
}

.ice-modifier__product-img {
  max-width: 200rem;
  margin: 10rem auto;
}

.rtl .ice-modifier__product-title--checkmark {
  left: -20rem;
  right: unset;
}

.info-dialog__content {
  padding: 120rem 30rem;
}

.info-dialog__title {
  font-size: 58rem;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.info-dialog__actions {
  padding-top: 20rem;
  margin-top: 40rem;
  background: none;
  min-height: unset;
  justify-content: center;
}

.info-dialog__actions > * {
  margin: 0 20rem;
}

.info-dialog__actions--button {
  min-width: 340rem;
  height: 84rem;
  font-size: 34rem;
}

.item-pack-group__page-container {
  padding: 0 0 0 0;
}

.item-pack-group__title {
  text-align: center;
  padding: 60rem;
  margin-bottom: 0;
}

.item-pack-group__item-pack {
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  padding: 30rem 30rem 60rem 30rem;
}

.item-pack-group__product {
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding: 30rem 40rem;
  gap: 30rem;
}

.item-pack-group__product--img {
  max-width: 200rem;
}

.item-pack-group__product--title {
  font-size: 46rem;
  text-align: left;
}

.item-pack-group__product--info {
  position: absolute;
  right: 10rem;
  top: 20rem;
}

.item-pack-group__actions {
  gap: 100rem;
  justify-content: center;
}

.item-pack-indicator {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  border-radius: var(--product-border-radius);
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: transform 0.5s;
}

.item-pack-indicator__ticket {
  --product-grid-columns: 260rem;
  --ticket-ratio: calc(var(--product-grid-columns) / 3);
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  margin: calc(var(--ticket-ratio) / 2 * -1);
  width: var(--ticket-ratio);
  height: var(--ticket-ratio);
  transform: rotate(45deg);
  background-color: var(--gray);
}

.item-pack-indicator__ticket--checked {
  background-color: var(--accent);
}

.item-pack-indicator__ticket--ripple {
  position: absolute;
  left: -70%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 1000ms linear infinite;
  background-color: var(--light-gray);
}

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  direction: ltr;
}

.keyboard__output {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  min-height: 122rem;
  margin: 80rem auto;
  border-radius: 80rem;
  background: var(--primary);
}

.keyboard__output--text {
  color: var(--accent);
  padding: 10rem 20rem;
  max-width: 90%;
}

.keyboard__keys {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.keyboard__key {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4rem 7rem 0 rgba(0, 0, 0, 0.18);
  border-radius: 40rem;
  width: 74rem;
  height: 64rem;
  font-size: 46rem;
  margin: 7rem;
  color: var(--tertiary);
  background: var(--secondary);
}

.keyboard__key:active {
  animation: keyStroke 100ms ease-in-out;
}

.keyboard__key--empty {
  visibility: hidden;
}

.keyboard__key--clear {
  text-transform: uppercase;
  font-size: 26rem;
  width: 100rem;
}

.keyboard__key--email, .keyboard__key--dotcom {
  min-width: fit-content;
  padding: 0 22rem;
  font-size: 32rem;
}

.keyboard--numeric .keyboard__output {
  margin: 60rem auto 40rem auto;
}

.keyboard--numeric .keyboard__keys {
  flex-direction: column;
}

.keyboard--numeric .keyboard__keys .row:last-of-type {
  justify-content: flex-end;
}

.keyboard--numeric .keyboard__keys .row:last-of-type .keyboard__key {
  color: var(--keyboardGray);
}

.keyboard--numpad .keyboard__output {
  max-width: 60%;
}

.keyboard--numpad .keyboard__keys {
  gap: 30rem;
}

.keyboard--numpad .keyboard__keys .row {
  gap: 30rem;
}

.keyboard--numpad .clear {
  width: 100%;
}

.languages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.languages__wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20rem;
}

.languages__title {
  margin: 20rem 0;
  font-size: 34rem;
}

.languages__type {
  transition: all 150ms ease-in;
  filter: var(--shadow-filter);
  opacity: 0.3;
}

.languages__type--image {
  border-radius: 50%;
  max-width: 72rem;
  border: 4rem solid var(--secondary);
}

.languages__type--active {
  transform-origin: center center;
  transform: scale(1.3);
  opacity: 1;
  filter: none;
}

.languages__type--active .languages__type--image {
  border: 4rem solid var(--accent);
}

.make-it-a-meal__title {
  text-align: center;
  padding: 60rem;
  margin-bottom: 0;
}

.make-it-a-meal__wrapper {
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  padding: 30rem 5rem 60rem 5rem;
}

.make-it-a-meal__product {
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding: 30rem 40rem;
  gap: 30rem;
}

.make-it-a-meal__product--img {
  max-width: 200rem;
}

.make-it-a-meal__product--title {
  font-size: 46rem;
  text-align: left;
}

.make-it-a-meal__product--info {
  position: absolute;
  right: 10rem;
  top: 20rem;
}

.make-it-a-meal__actions {
  gap: 100rem;
  justify-content: center;
}

.make-it-a-meal .scrollable-container {
  height: unset;
}

.modifiers-stepper__content {
  height: 95%;
}

.modifiers-stepper__page-container {
  padding: 0;
  overflow-y: unset;
}

.modifiers-stepper__top-menu {
  display: flex;
  align-items: center;
  min-height: 120rem;
  padding: 0 40rem;
  position: relative;
}

.modifiers-stepper__top-menu--title {
  position: absolute;
  font-size: 40rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.modifiers-stepper__actions {
  gap: 10rem;
  min-height: 200rem;
}

.ada .modifiers-stepper__content {
  max-width: 95%;
  height: 45vh;
}

.ada .modifiers-stepper .product-stepper-header {
  min-height: 400rem;
  background-position: bottom;
}

.ada .modifiers-stepper .product-stepper-header__product-img {
  max-width: 200rem;
  top: 40rem;
}

.nav-slider {
  height: 190rem;
  position: relative;
}

.nav-slider__wrapper {
  width: 100%;
  height: 100%;
  background: var(--navSliderBackground);
}

.nav-slider__container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 240rem;
  overflow-x: overlay;
  scroll-behavior: smooth;
}

.nav-slider__controller {
  position: absolute;
  background: var(--primary);
  color: var(--secondary);
  height: 100%;
  top: 0;
  width: 60rem;
}

.nav-slider__controller--left {
  left: 0;
}

.nav-slider__controller--right {
  right: 0;
}

.nav-slider__item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10rem 20rem;
}

.nav-slider__item--title {
  font-size: 28rem;
  text-transform: uppercase;
  color: var(--navSliderItemTitleColor);
}

.nav-slider__item--image {
  max-width: 100rem;
}

.nav-slider__item--selected {
  position: relative;
  background: var(--navSliderItemSelectedBackground);
}

.nav-slider__item--selected .nav-slider__item--title {
  color: var(--navSliderItemSelectedTitleColor);
}

.nav-slider__item--displayOnlyImage {
  padding: 0;
}

.nav-slider__item--displayOnlyImage .nav-slider__item--selected .nav-slider__item--title {
  display: none;
}

.nav-slider__item--displayOnlyImage .nav-slider__item--image {
  max-width: 240rem;
  padding: 30rem;
}

.nav-slider__item--displayOnlyText .nav-slider__item--image {
  display: none;
}

.nav-slider__firstItemFixed .nav-slider__wrapper {
  position: relative;
  padding-left: 240rem;
}

.nav-slider__firstItemFixed .nav-slider__controller--left {
  left: 240rem;
}

.nav-slider__firstItemFixed .nav-slider__item--home {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 240rem;
  box-shadow: 6rem 1rem 13rem -5rem rgba(51, 51, 51, 0.2705882353);
}

.nav-slider__firstItemFixed .nav-slider__item--home--selected {
  position: absolute;
}

.nav-slider.hasNavArrows .nav-slider__container {
  margin: 0 60rem;
  width: calc(100% - 60rem);
}

.nav-slider.hasNavArrows .nav-slider__wrapper {
  padding-right: 60rem;
}

.nav-slider.vertical {
  height: 100%;
  width: 190rem;
}

.nav-slider.vertical .nav-slider__wrapper {
  padding-left: unset;
}

.nav-slider.vertical.nav-slider__firstItemFixed .nav-slider__wrapper {
  padding-top: 190rem;
}

.nav-slider.vertical .nav-slider__container {
  grid-auto-flow: row;
  grid-auto-rows: minmax(205rem, 1fr);
  grid-auto-columns: unset;
}

.nav-slider.vertical .nav-slider__item--home {
  bottom: unset;
  width: 100%;
}

.ada .nav-slider.horizontal .nav-slider__container {
  grid-auto-columns: 240rem;
}

.ada .nav-slider.horizontal .nav-slider__firstItemFixed .nav-slider__wrapper {
  padding-left: 240rem;
}

.ada .nav-slider.horizontal .nav-slider__firstItemFixed .nav-slider__controller--left {
  left: 240rem;
}

.ada .nav-slider.horizontal .nav-slider__firstItemFixed .nav-slider__item--home {
  width: 240rem;
}

.rtl .nav-slider.horizontal .nav-slider__firstItemFixed .nav-slider__item--home {
  right: 0;
  left: unset;
}

.rtl .nav-slider.horizontal .nav-slider__firstItemFixed .nav-slider__wrapper {
  padding-right: 240rem;
  padding-left: unset;
}

.product-card-detail__change-step-button {
  border: 1rem solid var(--accent);
  padding: 0 10rem;
  margin-left: 10rem;
  border-radius: 70rem;
}

.product-card-sub-card-item__item {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-gap: 10rem;
  align-items: center;
  padding: 20rem;
}

.product-card-sub-card-item__item--image {
  max-width: 80rem;
}

.product-card-sub-card-item__item--product {
  display: flex;
  flex-direction: column;
}

.product-card-sub-card-item__item--header {
  display: flex;
  align-items: center;
  font-size: 28rem;
  padding-bottom: 6rem;
}

.product-card-sub-card-item__item--modifiers {
  display: flex;
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  flex-wrap: wrap;
}

.product-card-sub-card-item__item--quantity {
  display: inline-block;
  margin-right: 5rem;
}

.product-card-sub-card-item__item--price {
  display: inline-block;
  margin-left: 10rem;
}

.product-card-sub-card-item__item + .product-card-sub-card-item__item {
  padding-top: 0;
}

.product-card-sub-card-item__modifier--quantity {
  padding-right: 4rem;
}

.product-card-sub-card-item__modifier--title {
  padding-right: 4rem;
  display: contents;
}

.product-card-sub-card-item__actions {
  display: flex;
  justify-content: center;
}

.product-card {
  display: grid;
  grid-template-columns: 240rem auto;
  grid-gap: 30rem;
  width: 100%;
  height: 100%;
  padding: 20rem;
  background: var(--secondary);
  color: var(--primary);
  border-radius: 18rem;
}

.product-card__img {
  max-width: 150rem;
}

.product-card__title {
  font-size: 50rem;
  padding-right: 20rem;
}

.product-card__info {
  display: flex;
  flex-direction: column;
}

.product-card__contents-list {
  font-family: var(--fontSecondary);
  list-style-type: none;
  margin: 0;
  padding: 20rem 0;
}

.product-card__contents-list--item {
  font-size: 24rem;
  margin-bottom: 8rem;
}

.product-card__actions {
  align-self: flex-start;
}

.product-card__price {
  font-family: var(--fontSecondary);
  font-size: 42rem;
  text-align: right;
}

.product-card--promo-card {
  grid-template-columns: unset;
  padding: 20rem 40rem;
}

:host-context(html.touchlessEnabled) .product-card--basket-card {
  animation: none;
}

.product-card-sub-card-item {
  background: var(--secondary);
  filter: var(--shadow-filter);
  border-radius: 18rem;
  margin-top: 30rem;
}

.rtl .product-card__price {
  text-align: left;
}

.product-info__content {
  height: 95%;
}

.product-info__content--withoutTabs {
  height: auto;
}

.product-info__page-container {
  padding: 50rem 50rem;
}

.product-info__info--header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 30rem;
}

.product-info__info--img {
  margin: auto;
}

.product-info__info--title {
  font-size: 44rem;
}

.product-info__info--description {
  font-family: "Helvetica", sans-serif;
  font-weight: 300;
  font-size: 18rem;
}

.product-info__ingredients {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14rem 30rem;
  padding: 0 30rem;
}

.product-info__ingredients--ingredient {
  font-family: "Helvetica", sans-serif;
  font-size: 30rem;
  font-weight: bold;
  margin: 0;
}

.product-info__tabs {
  display: flex;
  flex-direction: column;
}

.product-info__tabs--header {
  display: flex;
  width: 100%;
  gap: 20rem;
  border-bottom: 3rem solid var(--accent);
  margin: 30rem 0;
}

.product-info__tab {
  background: var(--primary);
  color: var(--white);
  font-size: 30rem;
  flex: 1;
  min-height: 70rem;
  font-family: "Helvetica", sans-serif;
  font-weight: bold;
  border-top-left-radius: 6rem;
  border-top-right-radius: 6rem;
  transition: all 250ms ease-in-out;
}

.product-info__tab--selected {
  background: var(--accent);
  color: var(--primary);
}

.product-info__nutrition {
  font-family: "Helvetica", sans-serif;
  border-top: 14rem solid var(--primary);
  border-bottom: 14rem solid var(--primary);
}

.product-info__nutrition--article {
  display: flex;
  flex-direction: column;
  gap: 10rem;
  border-bottom: 3rem solid var(--primary);
  padding: 30rem 20rem;
}

.product-info__nutrition--label {
  font-size: 30rem;
  font-weight: bold;
}

.product-info__nutrition--value {
  font-size: 30rem;
  font-weight: 300;
}

.product-info__nutrition--description {
  font-size: 18rem;
  font-weight: 300;
  margin: 0;
}

.product-info__product-allergens {
  flex-wrap: wrap;
  gap: 20rem 40rem;
  margin: 20rem auto;
}

.product-info__allergen-icon {
  border: 1rem solid var(--tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20rem;
  width: 116rem;
  height: 116rem;
  border-radius: 100%;
}

.product-info__general-calories-message {
  font-family: "Helvetica", sans-serif;
  font-weight: 300;
  margin: 40rem auto;
  text-align: center;
  font-size: 18rem;
}

.product-stepper-header {
  background: var(--combo-bg) no-repeat;
  background-size: cover;
  min-height: 500rem;
  border-top-left-radius: 18rem;
  border-top-right-radius: 18rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-stepper-header__product-name {
  position: absolute;
  bottom: 20rem;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
}

.product-stepper-header__product-name > * {
  margin: 0;
  line-height: 1;
}

.product-stepper-header__product-img {
  position: absolute;
  max-width: 360rem;
  bottom: 100rem;
}

.product-stepper-header__product-img:first-child {
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.product-stepper-header__product-img.new-addition:nth-child(2n+3) {
  animation: slideInLeft 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.product-stepper-header__product-img.new-addition:nth-child(even) {
  animation: slideInRight 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.promo-stepper__page-container {
  padding: 100rem 0 0 0;
}

.promo-stepper__actions {
  gap: 10rem;
  min-height: 200rem;
}

.promos-select-method__option-box {
  width: 340rem;
  height: 340rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 20rem;
  margin-top: 34rem;
  height: 100%;
}

.promos-select-method__voucher-input {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
}

.scan {
  background: var(--secondary);
  filter: var(--shadow-filter);
  width: 340rem;
  height: 340rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20rem;
  margin-top: 34rem;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scrollable-container__scroll {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-container__scroll-nav {
  flex: 100rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary);
  box-shadow: 1rem -13rem 17rem 0 rgba(0, 0, 0, 0.05);
  border-bottom: 3rem solid var(--primary);
  z-index: 1;
  width: 100%;
}

.scrollable-container__scroll-nav--text {
  text-transform: uppercase;
}

.scrollable-container__scroll-nav--up, .scrollable-container__scroll-nav--down {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollable-container__icon {
  background: var(--primary);
  border-radius: 50%;
  width: 60rem;
  height: 60rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30rem;
}

.scrollable-container__icon--image {
  margin-bottom: -3rem;
}

.service-type-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  border-radius: 20rem;
  padding: 30rem;
  background: var(--secondary);
  filter: var(--shadow-filter);
}

.service-type-button__img {
  align-self: center;
  margin: 64rem;
  width: 272rem;
  height: 272rem;
}

.service-type-button__text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
}

.service-type-button__text--title {
  line-height: 1.1;
  font-size: 46rem;
}

.ada .service-type-button__text {
  padding: 0;
}

.ada .service-type-button__text--title {
  font-size: 38rem;
}

.ada .service-type-button__img {
  max-width: 65%;
}

.spinner {
  animation: rotate 1s infinite both;
}

.spinner__circle {
  width: 320rem;
  height: 320rem;
  border-radius: 50%;
  background: var(--primary);
}

.spinner__circle--icon {
  width: 200rem;
  height: 200rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
.status {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  max-width: 100%;
  min-height: 120rem;
  margin: 0 auto;
  padding: 30rem;
  pointer-events: none;
}

.status--hasBackground {
  background: rgba(0, 0, 0, 0.863);
}

.status__messages {
  max-width: 83%;
  margin: 0 auto;
}

.status__messages--message {
  font-size: 18rem;
  margin: 2rem 0;
  color: var(--secondary);
  font-weight: bold;
  font-family: var(--font-tertiary);
}

.status__color-code {
  width: 80rem;
  height: 80rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 20rem;
  transform: translateY(-50%);
}

.rtl .stepper__step::after {
  left: -50%;
  right: unset;
}

.suggestion-sales__page-container {
  padding: 100rem 0 0 0;
}

.suggestion-sales__header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30rem;
}

.suggestion-sales__header--button {
  position: relative;
  right: 250rem;
}

.suggestion-sales__actions {
  gap: 100rem;
}

.suggestion-sales--hidden {
  display: none;
}

.ticket-print-selection__content {
  padding: 120rem 30rem;
  height: 35%;
}

.ticket-print-selection__actions {
  margin-top: 60rem;
  margin-bottom: 60rem;
  background: none;
  min-height: unset;
  justify-content: center;
  gap: 100rem;
}

.ticket-print-selection__actions--button {
  min-width: 340rem;
  height: 84rem;
  font-size: 34rem;
}

.ticket-print-selection__ticket-options--button {
  width: 300rem;
}

.voucher-info {
  padding: 0 40rem;
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  min-height: 112rem;
  background: var(--secondary);
}

.voucher-info--positioned-top {
  position: absolute;
  top: 190rem;
  z-index: 1;
}

.voucher-info--button {
  font-size: 26rem;
}

.info-indicator {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: transform 0.5s;
  pointer-events: none;
  border-radius: var(--product-border-radius);
}

.info-indicator__ticket {
  --product-grid-columns: 260rem;
  --ticket-ratio: calc(var(--product-grid-columns) / 3);
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  margin: calc(var(--ticket-ratio) / 2 * -1);
  width: var(--ticket-ratio);
  height: var(--ticket-ratio);
  transform: rotate(45deg);
  background-color: var(--accent);
  pointer-events: initial;
}

.info-indicator__info {
  transform: rotate(-45deg);
  left: 12rem;
  position: absolute;
  top: 28rem;
  color: var(--primary);
  font-size: 22rem;
}

.info-indicator--reversed {
  border-radius: 18rem;
}

.info-indicator--reversed .info-indicator__ticket {
  --product-grid-columns: 520rem;
  bottom: unset;
  background-color: var(--accent);
}

.info-indicator--reversed .info-indicator__info {
  font-size: 40rem;
  left: 80rem;
  top: 110rem;
  color: var(--primary);
}

.rtl .info-indicator__ticket {
  left: 0;
  right: unset;
}

.rtl .info-indicator__info {
  left: 35rem;
  top: 2rem;
}

.rtl .info-indicator--reversed .info-indicator__info {
  left: 130rem;
  top: 70rem;
}

.app__ui-blocking-loader {
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.banners {
  background: var(--banners-bg);
}

.banners__touch-to-order-section {
  position: absolute;
  bottom: 220rem;
}

.checkout-error {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.checkout-error__square {
  margin: 50rem;
}

.cod-view {
  background: var(--codViewBackgroundColor);
}

.cod-view__header {
  height: 180rem;
  padding: 0 50rem;
}

.cod-view__title {
  font-size: 60rem;
}

.cod-view__total {
  padding: 20rem 50rem;
  min-height: 100rem;
}

.cod-view .footer {
  background: var(--footerDarkBackgroundColor);
}

.cod-view .footer__ada-button-image {
  display: none;
}

.cod-view .footer__ada-button-image--light {
  display: block;
}

.preorder__image {
  max-width: 250rem;
}

.ada .preorder__square {
  width: 400rem;
  height: 400rem;
}

.menu-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.menu__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 40rem;
  flex: 120rem 0 0;
  position: relative;
}

.menu__header .ui-button--menu-back {
  position: absolute;
  left: 0;
}

.menu__populars-title {
  padding: 0 40rem 40rem 40rem;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: var(--accent);
}

.menu .top-menu {
  height: 120rem;
}

.menu .footer {
  background: var(--footerDarkBackgroundColor);
}

.menu .footer__ada-button-image {
  display: none;
}

.menu .footer__ada-button-image--light {
  display: block;
}

.rtl .menu__header > button {
  right: 0;
  left: unset;
}

.service-types__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30rem;
}

.service-types__titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.service-types__titles--title {
  font-size: 170rem;
}

.service-types__titles--subtitle {
  font-size: 54rem;
}

.ada .service-types__buttons {
  max-width: 60%;
  margin: 0 auto;
}

.payment-retry__wrapper {
  padding: 120rem 30rem;
}

.payment-retry__wrapper--title {
  font-size: 58rem;
  max-width: 80%;
  margin: 0 auto;
}

.payment-retry__actions {
  padding: 20rem;
}

.payment-retry__actions--button {
  min-width: 340rem;
  height: 84rem;
  font-size: 34rem;
}

.ada .pos--icon {
  max-width: 130rem;
}

.epay__title {
  font-size: 46rem;
  text-align: center;
  margin: auto;
}

.epay__scanning, .epay__footer {
  display: flex;
  flex-direction: column;
}

.epay__scanning {
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  justify-content: center;
  flex: 1;
}

.epay__container {
  display: flex;
  justify-content: center;
  align-self: center;
}

.epay__messages--top {
  align-self: flex-end;
}

.epay__messages--bottom {
  align-self: flex-start;
}

.epay__footer {
  align-items: center;
  justify-content: center;
  min-height: 300rem;
}

.epay__footer > * {
  margin: 20rem auto;
}

.epay__qr-code-animation {
  width: 300rem;
  height: 300rem;
  margin: 0 auto;
}

.electronic-receipt-page__message {
  min-height: 300rem;
}

.electronic-receipt-page__continue-button-container {
  margin-top: 150rem;
}

.electronic-receipt-page__receiptUrl {
  max-width: 300rem;
}

.ada .electronic-receipt-page__message {
  min-height: 200rem;
}

.ada .electronic-receipt-page__continue-button-contaiƒner {
  margin-top: 50rem;
}

.receipt-options__header-title {
  margin-bottom: 150rem;
}

.receipt-options__container {
  margin-top: 150rem;
}

.receipt-options__receipt-option-button {
  font-size: 50rem;
  margin-bottom: 40rem;
}

.ada .receipt-options__header-title {
  margin-bottom: 50rem;
}

.ada .receipt-options__container {
  margin-top: 50rem;
}

.ada .receipt-options__receipt-option-button {
  font-size: 40rem;
  margin-bottom: 20rem;
}

.glory-video {
  border-radius: 20rem;
  max-width: clamp(600rem, 800rem, 90vw);
  margin: 0 auto;
}

.error-image {
  border-radius: 20rem;
  max-width: clamp(600rem, 800rem, 90vw);
  margin: 0 auto;
}

.glory-cash-info {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.glory-cash-info__message {
  font-size: 34rem;
  display: flex;
  justify-content: space-between;
}

.glory-cash-info__text {
  text-align: left;
}

.glory-cash-info__price {
  color: var(--accent);
  text-align: right;
}

.payment-selection__types {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30rem;
  padding: 50rem 0;
  width: 100%;
  justify-items: center;
  justify-content: center;
}

.payment-selection__type {
  border-radius: 20rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  background: var(--secondary);
  filter: var(--shadow-filter);
  position: relative;
}

.payment-selection__type--image {
  margin: auto;
  align-self: center;
  max-width: 360rem;
}

.payment-selection__type--payment-image {
  margin: 40rem auto;
}

.payment-selection__type--text {
  width: 100%;
  padding: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-selection__type--name {
  text-align: left;
  font-size: 46rem;
}

.payment-selection__single-item {
  grid-template-columns: 1fr;
}

.payment-selection__single-item .payment-selection__type {
  border-radius: 20rem;
  max-width: 450rem;
  max-height: unset;
}

.payment-selection__footer .footer {
  height: 120rem;
}

.payment-selection-listing {
  max-height: 1300rem;
}

.ada .payment-selection__types {
  flex: 1;
  width: 65%;
  padding: 40rem 0;
  margin: auto;
}

.ada .payment-selection__type--payment-image {
  max-width: 180rem;
  margin: 20rem auto;
}

.ada .payment-selection__type--text {
  padding: 15rem;
}

.ada .payment-selection__type--name {
  font-size: 36rem;
}

.ada .payment-selection-listing {
  max-height: 790rem;
}

.table-service-confirmation__flag {
  position: absolute;
  right: -33rem;
  bottom: 0;
}

.table-service-confirmation__square {
  position: relative;
  max-height: 700rem;
  background-color: var(--secondary);
}

.table-service-confirmation .footer {
  justify-content: center;
  display: flex;
}

.rtl .table-service-confirmation__flag {
  left: -33rem;
  right: unset;
}

.table-service-entry__options {
  margin: auto 50rem;
}

.table-service-entry__payment-types {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30rem;
  padding: 60rem 0;
  justify-content: center;
}

.table-service-entry__payment-type {
  border-radius: 20rem;
  align-items: stretch;
  background: var(--secondary);
  filter: var(--shadow-filter);
  position: relative;
}

.table-service-entry__payment-type--image {
  margin: 50rem auto;
  flex: 1;
}

.table-service-entry__payment-type--name {
  font-size: 46rem;
}

.ada .table-service-entry__payment-types {
  width: 65%;
  margin: 0 auto;
}

.ada .table-service-entry__payment-type--image {
  max-width: 180rem;
}

.table-service-selection__title {
  max-height: 190rem;
  padding: 0 60rem;
  text-align: center;
}

.table-service-selection__footer {
  height: var(--footer-height);
}

.table-service-unavailable__circle-image {
  max-width: 250rem;
}

.email-receipt-page__title {
  margin-top: 150rem;
}

.ada .email-receipt-page__title {
  margin-top: 0;
}

.sms-receipt-page__title {
  margin-top: 150rem;
}

.ada .sms-receipt-page__title {
  margin-top: 0;
}

.pay-tower-page {
  background-color: var(--black);
}

.pay-tower-page * {
  color: var(--secondary);
}

.pay-tower-page .page-header {
  flex: 2;
}

.pay-tower-page__content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: flex-end;
  flex: 1;
  position: relative;
}

.pay-tower-page__text {
  margin: auto;
}

.pay-tower-page__text--underlined {
  margin: 352rem 85rem;
}

.pay-tower-page__line {
  position: absolute;
  width: 69%;
  left: 22vw;
  bottom: 412rem;
}

.ada .customer-login__actions {
  flex-direction: row;
  gap: 30rem;
}

.enter-pin {
  display: flex;
  gap: 50rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.enter-pin__content {
  padding: 20rem 40rem;
  display: flex;
  flex-direction: column;
  gap: 30rem;
}

.enter-pin__message {
  font-size: 34rem;
  text-align: center;
  margin: 0;
}

.enter-pin__message--error {
  color: var(--error-red);
}

.enter-pin__actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30rem;
}

.qr-code-pin-attempts {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.qr-code-pin-attempts__message {
  text-align: center;
  font-size: 40rem;
}

.qr-code-pin-attempts__qrcode-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30rem;
}

.qrcode-register-identity {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.qrcode-register-identity__message {
  text-align: center;
  font-size: 50rem;
}

.qrcode-register-identity__qrcode-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30rem;
}

.scanning-face {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  height: 100%;
  width: 100%;
}

.scanning-face__message {
  font-size: 60rem;
}

.scanning-face__scanning {
  margin: 0 auto;
  position: relative;
  z-index: 0;
}

.scanning-face__scanning--scan-bar {
  position: absolute;
  display: block;
  width: 131%;
  height: 15rem;
  background-color: rgba(255, 75, 75, 0.6196078431);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: scan-animation-bar 0.75s infinite ease;
  animation-direction: alternate;
}

.scanning-face__scanning--icon {
  z-index: 0;
}

.scanning-face__scanning--icon .mouth,
.scanning-face__scanning--icon .eyes {
  stroke-dasharray: 500;
  stroke-dashoffset: 1000;
  stroke-width: 4;
  stroke: #020202;
  fill: rgba(2, 2, 2, 0);
  animation: appear 3s linear 1 forwards;
}

@keyframes scan-animation-bar {
  to {
    top: 90%;
  }
}
@keyframes appear {
  from {
    stroke-dashoffset: 500;
  }
  80% {
    fill: rgba(2, 2, 2, 0);
  }
  90% {
    stroke-dashoffset: 0;
    fill: rgb(2, 2, 2);
  }
  to {
    stroke-dashoffset: 0;
    fill: rgb(2, 2, 2);
  }
}
.heset-qr-code-login__title {
  font-size: 46rem;
  text-align: center;
  margin: auto;
}

.heset-qr-code-login__scanning, .heset-qr-code-login__footer {
  display: flex;
  flex-direction: column;
}

.heset-qr-code-login__scanning {
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  justify-content: center;
  flex: 1;
}

.heset-qr-code-login__container {
  display: flex;
  justify-content: center;
  align-self: center;
}

.heset-qr-code-login__messages--top {
  align-self: flex-end;
}

.heset-qr-code-login__messages--bottom {
  align-self: flex-start;
}

.heset-qr-code-login__footer {
  align-items: center;
  justify-content: center;
  min-height: 300rem;
}

.heset-qr-code-login__footer > * {
  margin: 20rem auto;
}

.heset-qr-code-login__qr-code-animation {
  width: 300rem;
  height: 300rem;
  margin: 0 auto;
}

.osiris-login-keyboard__wrapper {
  padding: 120rem 60rem;
}

.osiris-login-keyboard .keyboard__keys {
  max-width: 440rem;
  margin: 0 auto;
}

.osiris-login-scan__wrapper {
  padding: 120rem 60rem;
}

.osiris-order-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 30rem;
  border-radius: 18rem;
  background: var(--secondary);
  border: 6rem solid var(--accent);
}

.osiris-order-card__header, .osiris-order-card__footer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.osiris-order-card__header {
  margin: 0 30rem;
  padding: 12rem 0;
}

.osiris-order-card__footer {
  padding: 10rem 30rem;
}

.osiris-order-card__item {
  display: flex;
  align-items: center;
  margin: 20rem 0;
  padding: 0 30rem;
}

.osiris-order-card__header {
  border-bottom: 2rem solid var(--gray);
}

.osiris-order-card__product-img {
  max-width: 100rem;
}

.osiris-order-card__product-title {
  padding: 0 30rem;
  font-size: 30rem;
}

.osiris-order-card__actions {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 70rem;
}

.osiris-order-card__button {
  border-radius: 0;
  min-height: unset;
  width: 100%;
  height: 100%;
  font-size: 34rem;
  text-transform: uppercase;
}

.osiris-order-card__button--reorder {
  background: var(--primary);
  color: var(--secondary);
  border-bottom-left-radius: 11rem;
}

.osiris-order-card__button--full-order {
  background: var(--accent);
  color: var(--primary);
  border-bottom-right-radius: 11rem;
}

.osiris-order-history {
  background: var(--primary);
}

.osiris-order-history__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40rem 0;
}

.osiris-order-history__orders {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 40rem;
  flex: 1;
}

.osiris-order-history__footer {
  background: var(--primary);
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 210rem;
  padding-left: 80rem;
  padding-right: 30rem;
  align-items: center;
  justify-items: center;
  position: relative;
}

.osiris-order-history--generic-text {
  color: var(--secondary);
  margin: auto 60rem;
  font-size: 56rem;
}

.osiris-order-history__server-timeout-message, .osiris-order-history__spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.osiris-order-history .footer {
  background: var(--footerDarkBackgroundColor);
}

.osiris-order-history .footer__ada-button-image {
  display: none;
}

.osiris-order-history .footer__ada-button-image--light {
  display: block;
}

.osiris-order-number-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 30rem;
}

.osiris-order-number-info__save-button {
  background: var(--secondary);
  filter: var(--shadow-filter);
  padding: 20rem 40rem;
  display: flex;
  align-items: center;
  font-size: 60rem;
  margin: 0 auto;
  border-radius: 18rem;
  gap: 40rem;
}

.osiris-order-number-info__save-button--name {
  display: flex;
  gap: 11rem;
  margin-left: 40rem;
  color: var(--primary);
}

.osiris-order-review {
  background: var(--primary);
}

.osiris-order-review__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40rem 0;
}

.osiris-order-review__orders {
  display: grid;
  grid-auto-rows: min-content;
  width: 100%;
  height: 100%;
  grid-gap: 30rem;
  padding: 0 40rem;
  flex: 1;
}

.osiris-order-review .footer {
  background: var(--footerDarkBackgroundColor);
}

.osiris-order-review .footer__ada-button-image {
  display: none;
}

.osiris-order-review .footer__ada-button-image--light {
  display: block;
}

.osiris-process-order__container {
  display: grid;
  grid-template-rows: 2fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.osiris-process-order__container--title {
  font-size: 80rem;
}

.osiris-process-order__container--subtitle {
  font-size: 60rem;
  text-transform: none;
}

.osiris-scan {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.osiris-scan__scan-image {
  margin-top: 120rem;
}

.osiris-scan__icon--bounce {
  margin-top: 120rem;
}

.osiris-login__osiris-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  flex: 1;
  gap: 30rem;
  padding: 0 100rem 100rem 100rem;
}

.osiris-login__osiris-options--button {
  background: var(--secondary);
  filter: var(--shadow-filter);
  border-radius: 18rem;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30rem;
  gap: 40rem;
}

.osiris-login__osiris-options--title {
  font-size: 64rem;
  justify-self: flex-start;
  display: flex;
  gap: 11rem;
}

.ada .osiris-login__osiris-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex: 0;
  margin: auto 0;
  padding: 0 40rem;
}

.ada .osiris-login__osiris-options--button {
  padding: 0 15rem;
}

.ada .osiris-login__osiris-options--img {
  max-width: 140rem;
}

.ada .osiris-login__osiris-options--title {
  font-size: 48rem;
}

.ada .osiris-login .page-header__title {
  padding: 24rem;
}