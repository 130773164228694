.osiris-order-review {
  background: var(--primary);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40rem 0;
  }

  &__orders {
    display: grid;
    grid-auto-rows: min-content;
    width: 100%;
    height: 100%;
    grid-gap: 30rem;
    padding: 0 40rem;
    flex: 1;
  }

  .footer {
    background: var(--footerDarkBackgroundColor);

    &__ada-button-image {
      display: none;

      &--light {
        display: block;
      }
    }
  }
}
