.glory-cash-info {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &__message {
    font-size: 34rem;
    display: flex;
    justify-content: space-between;
  }

  &__text {
    text-align: left;
  }

  &__price {
    color: var(--accent);
    text-align: right;
  }
}
