.info-indicator {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: transform 0.5s;
  pointer-events: none;
  border-radius: var(--product-border-radius);

  &__ticket {
    --product-grid-columns: 260rem;
    --ticket-ratio: calc(var(--product-grid-columns) / 3);
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin: calc((var(--ticket-ratio) / 2) * -1);
    width: var(--ticket-ratio);
    height: var(--ticket-ratio);
    transform: rotate(45deg);
    background-color: var(--accent);
    pointer-events: initial;
  }

  &__info {
    transform: rotate(-45deg);
    left: 12rem;
    position: absolute;
    top: 28rem;
    color: var(--primary);
    font-size: 22rem;
  }

  &--reversed {
    border-radius: 18rem;

    .info-indicator__ticket {
      --product-grid-columns: 520rem;
      bottom: unset;
      background-color: var(--accent);
    }

    .info-indicator__info {
      font-size: 40rem;
      left: 80rem;
      top: 110rem;
      color: var(--primary);
    }
  }
}

.rtl {
  .info-indicator {
    &__ticket {
      left: 0;
      right: unset;
    }

    &__info {
      left: 35rem;
      top: 2rem;
    }

    &--reversed {
      .info-indicator__info {
        left: 130rem;
        top: 70rem;
      }
    }
  }
}
