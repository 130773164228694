.item-pack-indicator {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  border-radius: var(--product-border-radius);
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: transform 0.5s;

  &__ticket {
    --product-grid-columns: 260rem;
    --ticket-ratio: calc(var(--product-grid-columns) / 3);
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin: calc((var(--ticket-ratio) / 2) * -1);
    width: var(--ticket-ratio);
    height: var(--ticket-ratio);
    transform: rotate(45deg);
    background-color: var(--gray);

    &--checked {
      background-color: var(--accent);
    }

    &--ripple {
      position: absolute;
      left: -70%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: scale(0);
      animation: ripple 1000ms linear infinite;
      background-color: var(--light-gray);
    }
  }
}
