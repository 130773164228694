.osiris-process-order {
  &__container {
    display: grid;
    grid-template-rows: 2fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    text-align: center;

    &--title {
      font-size: 80rem;
    }

    &--subtitle {
      font-size: 60rem;
      text-transform: none;
    }
  }
}
