.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  direction: ltr;

  &__output {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    min-height: 122rem;
    margin: 80rem auto;
    border-radius: 80rem;
    background: var(--primary);

    &--text {
      color: var(--accent);
      padding: 10rem 20rem;
      max-width: 90%;
    }
  }

  &__keys {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }

  &__key {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4rem 7rem 0 rgba(0, 0, 0, 0.18);
    border-radius: 40rem;
    width: 74rem;
    height: 64rem;
    font-size: 46rem;
    margin: 7rem;
    color: var(--tertiary);
    background: var(--secondary);

    &:active {
      animation: keyStroke 100ms ease-in-out;
    }

    &--empty {
      visibility: hidden;
    }

    &--clear {
      text-transform: uppercase;
      font-size: 26rem;
      width: 100rem;
    }

    &--email,
    &--dotcom {
      min-width: fit-content;
      padding: 0 22rem;
      font-size: 32rem;
    }
  }

  // Numeric Keyboard Specific Styles
  &--numeric {
    .keyboard__output {
      margin: 60rem auto 40rem auto;
    }

    .keyboard__keys {
      flex-direction: column;

      .row {
        &:last-of-type {
          justify-content: flex-end;

          .keyboard__key {
            color: var(--keyboardGray);
          }
        }
      }
    }
  }

  &--numpad {
    .keyboard__output {
      max-width: 60%;
    }

    .keyboard__keys {
      gap: 30rem;

      .row {
        gap: 30rem;
      }
    }

    .clear {
      width: 100%;
    }
  }
}
