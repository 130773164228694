.pay-tower-page {
  background-color: var(--black);

  * {
    color: var(--secondary);
  }

  .page-header {
    flex: 2;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-end;
    flex: 1;
    position: relative;
  }

  &__text {
    margin: auto;

    &--underlined {
      margin: 352rem 85rem;
    }
  }

  &__line {
    position: absolute;
    width: 69%;
    left: 22vw;
    bottom: 412rem;
  }
}
