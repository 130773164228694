.cod-view {
  background: var(--codViewBackgroundColor);

  &__header {
    height: 180rem;
    padding: 0 50rem;
  }

  &__title {
    font-size: 60rem;
  }

  &__total {
    padding: 20rem 50rem;
    min-height: 100rem;
  }

  .footer {
    background: var(--footerDarkBackgroundColor);

    &__ada-button-image {
      display: none;

      &--light {
        display: block;
      }
    }
  }
}
