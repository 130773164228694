.menu-buttons-grid {
  display: grid;
  grid-gap: 30rem;
  grid-template-rows: minmax(300rem, 1fr);
  width: 100%;
  padding: 8rem 40rem;

  > * {
    width: 100%;
    height: 100%;
  }
}

.modifiers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 260rem);
  grid-auto-rows: minmax(370rem, 1fr);
  justify-content: center;
  gap: 30rem;
  padding: 30rem 40rem;
  width: 100%;

  .product {
    padding: 24rem 15rem;
  }

  &.dual-modifiers {
    grid-template-columns: 1fr 1fr;
    max-width: 70%;
    margin: 60rem auto;
    grid-gap: 60rem;
    padding: 20rem 70rem;

    .product {
      &.selected {
        transform: scale(1.15);
      }
    }
  }
}

.highlighted-items {
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 40rem;

  :nth-child(2n + 1):last-child {
    grid-column: 1 / -1;
  }
}
