.product-info {
  &__content {
    height: 95%;

    &--withoutTabs {
      height: auto;
    }
  }

  &__page-container {
    padding: 50rem 50rem;
  }

  &__info {
    &--header {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: center;
      gap: 30rem;
    }

    &--img {
      margin: auto;
    }

    &--title {
      font-size: 44rem;
    }

    &--description {
      font-family: 'Helvetica', sans-serif;
      font-weight: 300;
      font-size: 18rem;
    }
  }

  &__ingredients {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 14rem 30rem;
    padding: 0 30rem;

    &--ingredient {
      font-family: 'Helvetica', sans-serif;
      font-size: 30rem;
      font-weight: bold;
      margin: 0;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: column;

    &--header {
      display: flex;
      width: 100%;
      gap: 20rem;
      border-bottom: 3rem solid var(--accent);
      margin: 30rem 0;
    }
  }

  &__tab {
    background: var(--primary);
    color: var(--white);
    font-size: 30rem;
    flex: 1;
    min-height: 70rem;
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    border-top-left-radius: 6rem;
    border-top-right-radius: 6rem;
    transition: all 250ms ease-in-out;

    &--selected {
      background: var(--accent);
      color: var(--primary);
    }
  }

  &__nutrition {
    font-family: 'Helvetica', sans-serif;
    border-top: 14rem solid var(--primary);
    border-bottom: 14rem solid var(--primary);

    &--article {
      display: flex;
      flex-direction: column;
      gap: 10rem;
      border-bottom: 3rem solid var(--primary);
      padding: 30rem 20rem;
    }

    &--label {
      font-size: 30rem;
      font-weight: bold;
    }

    &--value {
      font-size: 30rem;
      font-weight: 300;
    }

    &--description {
      font-size: 18rem;
      font-weight: 300;
      margin: 0;
    }
  }

  &__product-allergens {
    flex-wrap: wrap;
    gap: 20rem 40rem;
    margin: 20rem auto;
  }

  &__allergen-icon {
    border: 1rem solid var(--tertiary);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20rem;
    width: 116rem;
    height: 116rem;
    border-radius: 100%;
  }

  &__general-calories-message {
    font-family: 'Helvetica', sans-serif;
    font-weight: 300;
    margin: 40rem auto;
    text-align: center;
    font-size: 18rem;
  }
}
