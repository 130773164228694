.product {
  --product-border-radius: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25rem;
  width: 100%;
  height: 100%;
  position: relative;
  filter: var(--shadow-filter);
  color: var(--tertiary);
  background-color: var(--secondary);
  border-radius: var(--product-border-radius);

  &--disabled {
    filter: none;
    background-color: var(--gray);

    & > :not(.disabled--overlay) {
      opacity: 0.5;
    }
  }

  &--hasBackground {
    background-image: var(--product-bg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
  }

  &--hasChargeThreshold {
    .product__subtitle {
      position: absolute;
      left: 0;
      right: 0;
      line-height: 1;
    }
  }

  &--displayOnlyImage {
    flex-direction: row;
    padding: 0;
    align-items: center;

    .product {
      padding: unset;

      &__img {
        width: unset;
        height: unset;
        max-width: 100%;
        margin: 0 auto;
        padding: unset;
        border-radius: inherit;
      }

      &__info,
      &__title {
        display: none;
      }
    }
  }

  &--displayOnlyText {
    .product {
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;
      }

      &__img {
        display: none;
      }
    }
  }

  &::after {
    content: '';
    bottom: -30rem;
    width: 0;
    height: 0;
    opacity: 0;
    border-left: 30rem solid transparent;
    border-right: 30rem solid transparent;
    border-top: 30rem solid transparent;
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-out;
  }

  &.selected {
    box-shadow: inset 0 0 0 5rem var(--accent);

    &::after {
      border-top: 30rem solid var(--accent);
      opacity: 1;
    }
  }

  &__header {
    position: relative;
    margin-bottom: 30rem;
    width: 100%;
  }

  &__button {
    z-index: 1;
  }

  &__title {
    width: 100%;
    text-align: left;
    font-size: 32rem;
    line-height: 1;
  }

  &__img {
    max-width: 200rem;
  }

  &__info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--calories {
      font-family: var(--font-secondary);
      font-size: 24rem;
    }

    &--calories-count {
      margin-right: 5rem;
    }

    &--icon {
      margin-left: auto;
    }
  }

  &__popular-button {
    background: var(--accent);
  }

  &--modifier {
    button {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: inherit;
    }

    &.selected {
      box-shadow: inset 0 0 0 5rem var(--accent);
      filter: none;

      &::after {
        display: none;
      }

      .modifier-checkmark {
        display: block;
      }
    }

    .product__title {
      text-align: center;
      font-size: 24rem;
    }

    .product__subtitle {
      font-family: var(--font-secondary);
      font-size: 24rem;
      margin: 5rem auto;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      line-height: 1;
    }

    .product__img {
      max-width: 180rem;
    }

    .modifier-checkmark {
      display: none;
      position: absolute;
      top: -30rem;
      right: -20rem;

      // Change Icon Color
      use {
        --icon-primary-color: var(--tertiary);
        --icon-secondary-color: var(--secondary);
      }
    }
  }
}

.disabled--overlay {
  background-color: var(--dark-gray-opacity-5);
  color: var(--secondary);
  position: absolute;
  z-index: 1;
  opacity: 1;
  transform: translate(2.5%, -50%);
  width: 95%;
  font-size: 40rem;
  font-weight: 100;
  text-align: center;
  padding: 30rem 0;
  top: 50%;
  left: 0;
}

.navBarVerticalOrientation {
  .product {
    &__img {
      width: unset;
      height: unset;
      max-width: 150rem;
    }

    // &__title {
    //   font-size: 22rem;
    // }
  }
}
