.heset-qr-code-login {
  &__title {
    font-size: 46rem;
    text-align: center;
    margin: auto;
  }

  &__scanning,
  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__scanning {
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
    justify-content: center;
    flex: 1;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  &__messages {
    &--top {
      align-self: flex-end;
    }

    &--bottom {
      align-self: flex-start;
    }
  }

  &__footer {
    align-items: center;
    justify-content: center;
    min-height: 300rem;

    & > * {
      margin: 20rem auto;
    }
  }

  &__qr-code-animation {
    width: 300rem;
    height: 300rem;
    margin: 0 auto;
  }
}
