.button-modifier-list {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &--title {
      margin: 0;
      min-height: 36rem;
    }
  }
}
