.ticket-print-selection {
  &__content {
    padding: 120rem 30rem;
    height: 35%;
  }

  &__actions {
    margin-top: 60rem;
    margin-bottom: 60rem;
    background: none;
    min-height: unset;
    justify-content: center;
    gap: 100rem;

    &--button {
      min-width: 340rem;
      height: 84rem;
      font-size: 34rem;
    }
  }

  &__ticket-options {
    &--button {
      width: 300rem;
    }
  }
}
