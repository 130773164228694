.enter-pin {
  display: flex;
  gap: 50rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__content {
    padding: 20rem 40rem;
    display: flex;
    flex-direction: column;
    gap: 30rem;
  }

  &__message {
    font-size: 34rem;
    text-align: center;
    margin: 0;

    &--error {
      color: var(--error-red);
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30rem;
  }
}
