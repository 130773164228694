.product-stepper-header {
  background: var(--combo-bg) no-repeat;
  background-size: cover;
  min-height: 500rem;
  border-top-left-radius: 18rem;
  border-top-right-radius: 18rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__product-name {
    position: absolute;
    bottom: 20rem;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;

    > * {
      margin: 0;
      line-height: 1;
    }
  }

  &__product-img {
    position: absolute;
    max-width: 360rem;
    bottom: 100rem;

    &:first-child {
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }

    &.new-addition {
      &:nth-child(2n + 3) {
        animation: slideInLeft 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }

      &:nth-child(even) {
        animation: slideInRight 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }
}
