.stepper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-content: center;
  padding: 30rem 10rem;
  height: 100%;

  &__step {
    display: none;
    justify-items: center;
    align-items: flex-start;
    grid-template-rows: 60rem auto;
    height: 100%;
    text-align: center;
    position: relative;

    &--visible {
      display: grid;
    }

    &--last-item::after {
      display: none;
    }

    &:last-of-type::after {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      height: 10rem;
      width: 100%;
      right: -50%;
      bottom: 35rem;
      background: linear-gradient(to left, var(--comboStepperMeterColor) 50%, var(--accent) 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition: background-position 800ms ease;
      z-index: 0;
    }

    &--name {
      font-size: 18rem;
      line-height: 1;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      color: var(--comboStepperMeterColor);
      font-family: var(--font-tertiary);
      transition: color 250ms linear;

      &::after {
        content: '';
        display: inline-block;
        height: 6rem;
        width: 0;
        border-radius: 4rem;
        background: var(--accent);
      }
    }

    &--circle {
      width: 40rem;
      height: 40rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      transition: all 200ms ease-in-out;
      border: 5rem solid var(--comboStepperMeterColor);
      background: var(--primary);
      z-index: 1;

      .stepper__step--icon {
        display: none;
      }

      .stepper__step--image {
        max-width: 46rem;
        padding: 6rem;
        animation: scaleInCenter 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }

    &--selected {
      &::after {
        background-position: left bottom;
      }

      .stepper__step--circle {
        border-color: var(--secondary);
        background: var(--secondary);

        .stepper__step--icon {
          display: inline-block;
        }
      }
    }

    &--active {
      .stepper__step--name {
        color: var(--secondary);

        &::after {
          width: 100%;
          animation: scaleInHorCenter 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
      }

      .stepper__step--circle {
        border-color: var(--secondary);
        background: var(--secondary);
        transform: scale(1.8);
      }
    }
  }
}
