.app {
  &__ui-blocking-loader {
    z-index: 100000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
}
