// Default Styles
html {
  overflow: hidden;
  touch-action: none;
  font-size: calc(1vh / 1920 * 100); // 1 rem for Full HD resolution
}

body {
  position: relative;
  margin: auto;
  height: 100vh;
  overflow: hidden;
  font-size: 18rem;
  background: var(--mainBackgroundColor);
  font-family: var(--fontDefault);
  color: var(--primary);
  aspect-ratio: 9 / 16;

  &.kioskDevice {
    width: 100%;
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--primary);
  font-weight: normal;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-container--grid {
  display: grid;
  height: 100%;
}

.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100rem;

  &__title {
    text-align: center;
    padding: 40rem;
  }
}

.page-footer {
  display: flex;
  align-items: center;
  padding: 0 50rem;
  height: 170rem;
}

// Grids
.grid {
  display: grid;
  place-items: center;
  grid-auto-rows: 1fr;
}

.rtl {
  direction: rtl;
}
