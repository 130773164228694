.table-service-confirmation {
  &__flag {
    position: absolute;
    right: -33rem;
    bottom: 0;
  }

  &__square {
    position: relative;
    max-height: 700rem;
    background-color: var(--secondary);
  }

  .footer {
    justify-content: center;
    display: flex;
  }
}

.rtl {
  .table-service-confirmation__flag {
    left: -33rem;
    right: unset;
  }
}
