.button {
  &__info-button {
    position: absolute;
    top: 10rem;
    right: 0;
  }
}

.rtl {
  .button {
    .product__title {
      padding-left: 20rem;
      padding-right: unset;
      text-align: right;
    }

    &__info-button {
      left: 0;
      right: unset;
      margin-left: 10rem;
      margin-right: unset;
    }
  }
}
