.popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--popup-bg);

  &__content {
    padding: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    max-height: 95%;
    border-radius: 20rem;
    background: var(--popupsContentBackgroundColor, var(--secondary));
  }

  &__page-container {
    padding: 100rem 50rem;
    overflow-y: auto;
  }

  &__title {
    margin-bottom: 30rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 150rem;
    padding: 0 30rem;
    border-bottom-left-radius: 18rem;
    border-bottom-right-radius: 18rem;
    background: var(--popupsFooterBackgroundColor, var(--primary));
    justify-content: space-between;
  }

  &__actionsAllergens {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 150rem;
    padding: 0 30rem;
    border-bottom-left-radius: 18rem;
    border-bottom-right-radius: 18rem;
    background: var(--popupsFooterBackgroundColor, var(--primary));
    justify-content: center;
  }

  // Remove bottom border from .scroll-nav used in touchless mode
  .scroll-nav {
    border-bottom: 0;
  }
}
