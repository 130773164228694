.basket {
  align-items: flex-end;
  height: 76vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 300rem;
  z-index: 1;

  &__content {
    background: var(--basketBackgroundColor);
    border-radius: unset;
    padding: 180rem 0 0 0;
  }

  &__header {
    min-height: 120rem;

    &--button {
      position: absolute;
      left: 46rem;
      top: 50rem;
    }
  }

  &__total {
    padding: 10rem 30rem;
  }
}

.ada {
  .basket {
    top: initial;
    height: calc(50vh - var(--footer-height));
    bottom: var(--footer-height);
  }
}

.rtl {
  .basket__header--button {
    right: 46rem;
    left: unset;
  }
}
