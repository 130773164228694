.osiris-scan {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__scan-image {
    margin-top: 120rem;
  }

  &__icon--bounce {
    margin-top: 120rem;
  }
}
