.osiris-login-keyboard {
  &__wrapper {
    padding: 120rem 60rem;
  }

  .keyboard__keys {
    max-width: 440rem;
    margin: 0 auto;
  }
}
