.product-card-sub-card-item {
  &__item {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-gap: 10rem;
    align-items: center;
    padding: 20rem;

    &--image {
      max-width: 80rem;
    }

    &--product {
      display: flex;
      flex-direction: column;
    }

    &--header {
      display: flex;
      align-items: center;
      font-size: 28rem;
      padding-bottom: 6rem;
    }

    &--modifiers {
      display: flex;
      margin: 0;
      list-style-type: none;
      padding-left: 0;
      flex-wrap: wrap;
    }

    &--quantity {
      display: inline-block;
      margin-right: 5rem;
    }

    &--price {
      display: inline-block;
      margin-left: 10rem;
    }
  }

  &__item + &__item {
    padding-top: 0;
  }

  &__modifier {
    &--quantity {
      padding-right: 4rem;
    }

    &--title {
      padding-right: 4rem;
      display: contents;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}
