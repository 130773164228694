.confirm-dialog {
  &__content {
    padding: 120rem 30rem;
  }

  &__title {
    font-size: 58rem;

    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  &__actions {
    margin-top: 60rem;
    background: none;
    min-height: unset;
    justify-content: center;
    gap: 100rem;

    &--button {
      min-width: 340rem;
      height: 84rem;
      font-size: 34rem;
    }
  }
}
