.dropdown {
  grid-column: 1/-1;
  height: 320rem;
  width: 100%;
  margin: 0 auto;
  padding: 20rem;
  background: var(--background-gray);
  box-shadow: inset 0 0 16rem 5rem rgba(0, 0, 0, 0.16);

  &__modifier {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-between;

    &--disabled {
      opacity: 0.3;
      filter: grayscale(100%);
      pointer-events: none;
    }

    &--image {
      max-width: 150rem;
    }

    &--button {
      font-size: 22rem;
      padding: 8rem 30rem;
      min-height: 52rem;
    }
  }
}

.ada {
  .dropdown__modifiers {
    height: 100%;
  }
  .dropdown__modifier--image {
    max-width: 100rem;
  }
}
