.calories {
  &__actions {
    position: relative;
    padding-bottom: 50rem;
  }

  &__pagination {
    position: absolute;
    right: 0;

    &--number {
      font-size: 40rem;
      width: 60rem;
      color: var(--secondary);
      text-align: center;
    }
  }
}
