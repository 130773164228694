.table-service-selection {
  &__title {
    max-height: 190rem;
    padding: 0 60rem;
    text-align: center;
  }

  &__footer {
    height: var(--footer-height);
  }
}
