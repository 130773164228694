@use 'mixins';

.circle {
  width: 600rem;
  height: 600rem;
  padding: 30rem;
  border-radius: 50%;
  background: var(--primary);
  filter: var(--shadow-filter);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &--medium {
    width: 360rem;
    height: 360rem;
  }

  &--small {
    width: 300rem;
    height: 300rem;
  }
}

.square {
  background: var(--primary);
  filter: var(--shadow-filter);
  border-radius: 20rem;
  padding: 80rem;
  position: relative;

  > * {
    color: var(--secondary);
  }
}
