/*

#
###
##### GENERAL
###
#

*/

h1 {
  font-size: 80rem;
}

h2 {
  font-size: 60rem;
}

h3 {
  font-size: 46rem;
}

h4 {
  font-size: 36rem;
}

h5 {
  font-size: 26rem;
}

h6 {
  font-size: 16rem;
}

.big--heading {
  font-size: 230rem;
}

// Button Resets
button {
  border: none;
  outline: unset;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  margin: 0;
  padding: 0;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;

    > * {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

video {
  height: -webkit-fill-available;
}

.logo {
  max-width: 200rem;
  content: var(--logo);

  &.large {
    max-width: 300rem;
  }

  &.medium {
    max-width: 200rem;
  }

  &.small {
    max-width: 100rem;
  }
}

.font--secondary {
  font-family: var(--fontSecondary);
}

.font--tertiary {
  font-family: var(--fontTertiary);
}

.font--light {
  font-family: var(--fontLight);
}

.bold {
  font-weight: bold;
}

/*

#
###
##### HELPERS
###
#

*/

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-full {
  display: flex;
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.scrollY {
  overflow-y: auto;
}

.scrollX {
  overflow-x: overlay;
}

// Flex shorthand classes
@for $i from 1 through 10 {
  .flex--#{$i} {
    flex: #{$i};
  }
}

.flex {
  display: flex;
}

.flex--center {
  place-items: center;
  justify-content: center;
}

.flex--column {
  flex-direction: column;
}

.flex--column-reverse {
  flex-direction: column-reverse;
}

.place-center {
  place-items: center;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.self-end {
  justify-self: end;
}

.self-start {
  justify-self: start;
}

.self-center {
  justify-self: center;
}

.align-baseline {
  align-items: baseline;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

// Text Specific Styles
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase-first-letter {
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
}

.transform--none {
  text-transform: none;
}

// Colors Helpers
.color--primary {
  color: var(--primary);
}

.color--accent {
  color: var(--accent);
}

.color--red {
  color: var(--red);
}

.color--errorRed {
  color: var(--errorRed);
}

.color--orange {
  color: var(--orange);
}

.color--green {
  color: var(--accent);
}

.color--tertiary {
  color: var(--tertiary);
}

.color--lightGray {
  color: var(--lightGray);
}

.color--white {
  color: var(--secondary);
}

// UTILITY CLASSES FOR BACKGROUND COLORS
.bg--primary {
  background: var(--primary);
}

.bg--white {
  background: var(--secondary);
}

.bg--orange {
  background: var(--orange);
}

.bg--accent {
  background: var(--accent);
}

.bg--darkGray {
  background: var(--darkGray);
}

.bg--lightGray {
  background: var(--backgroundLightGray);
}

.bg--blue {
  background: var(--blue);
}

.bg--red {
  background: var(--red);
}

.bg--purple {
  background: var(--purple);
}

// Height Utilities
.height50 {
  height: 50%;
}

.height60 {
  height: 60%;
}

.height70 {
  height: 70%;
}

.height80 {
  height: 80%;
}

.height90 {
  height: 90%;
}

.height100 {
  height: 100%;
}

// Width Utilities
.width50 {
  width: 50%;
}

.width60 {
  width: 60%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}

.width100 {
  width: 100%;
}

// Rows and Cols utility classes
// .row-{nr} to place the container at a specific row in the grid
// .col-{nr} to place the container at a specific row in the grid
// Used for dropdowns containers

@for $i from 1 through 40 {
  .row-#{$i} {
    grid-row: #{$i};
  }
}

@for $i from 1 through 40 {
  .col-#{$i} {
    grid-column: #{$i};
  }
}

// Font sizes helper classes
// These clases overwrite any other font-sizes applied
@for $i from 1 through 120 {
  .fs--#{$i} {
    font-size: #{$i}rem;
  }
}

// Spacers
@for $i from 1 through 10 {
  $j: $i * 10;

  .spacer--#{$j} {
    display: block;
    height: #{$j}rem;
    width: #{$j}rem;
  }
}

@for $i from 8 through 30 {
  $j: $i * 10;
  .size--#{$j} {
    width: #{$j}rem;
    height: #{$j}rem;
    max-width: #{$j}rem;
    max-height: #{$j}rem;
  }
}

@for $j from 1 through 90 {
  .size--#{$j} {
    width: #{$j}rem;
    height: #{$j}rem;
    max-width: #{$j}rem;
    max-height: #{$j}rem;
  }
}

@for $i from 10 through 100 {
  $j: $i * 10;
  .size--#{$j} {
    width: #{$j}rem;
    height: #{$j}rem;
    max-width: #{$j}rem;
    max-height: #{$j}rem;
  }
}

// Use these helpers to adjust margins and paddings

@for $i from 0 through 15 {
  $j: $i * 10;

  .ml--#{$j} {
    margin-left: #{$j}rem;
  }

  .mr--#{$j} {
    margin-right: #{$j}rem;
  }

  .mt--#{$j} {
    margin-top: #{$j}rem;
  }

  .mb--#{$j} {
    margin-bottom: #{$j}rem;
  }

  .m--#{$j} {
    margin: #{$j}rem;
  }
}

@for $i from 0 through 14 {
  $j: $i * 10;

  .pl--#{$j} {
    padding-left: #{$j}rem;
  }

  .pr--#{$j} {
    padding-right: #{$j}rem;
  }

  .pt--#{$j} {
    padding-top: #{$j}rem;
  }

  .pb--#{$j} {
    padding-bottom: #{$j}rem;
  }

  .p--#{$j} {
    padding: #{$j}rem;
  }
}

.p0 {
  padding: 0;
}

.m0 {
  margin: 0;
}

.m--auto {
  margin: auto;
}

.mt--auto {
  margin-top: auto;
}

.mb--auto {
  margin-bottom: auto;
}

.unstyled-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// Gap Helpers
@for $i from 0 through 20 {
  $j: $i * 5;

  .gap--#{$j} {
    gap: #{$j}rem;
  }
}

/*
#
###
##### SCROLLBAR STYLES
###
#

*/

::-webkit-scrollbar {
  width: 10rem;
  height: 10rem;
  background: rgba(0, 0, 0, 0);
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5rem;
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5rem;
  background-color: var(--accent);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 5rem;
  background-color: var(--accent);
}

// Used to hide scrollbars where needed
.hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}
html.touchlessEnabled {
  .hideScrollbar {
    touch-action: none;
  }
}

.eventsNone {
  pointer-events: none;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

// Cols helper clases
.cols--1 {
  grid-template-columns: 1fr;
}

.cols--2 {
  grid-template-columns: repeat(2, 1fr);
}

.cols--3 {
  grid-template-columns: repeat(3, 1fr);
}

.cols--4 {
  grid-template-columns: repeat(4, 1fr);
}

.top3 {
  grid-template-columns: repeat(2, 1fr);

  & > :last-child {
    grid-column: 1 / -1;
  }
}

.color-picker-btn {
  font-size: 20rem !important ;
  min-width: 80rem;
  background-color: rgb(66, 149, 226) !important;
  border-radius: 10rem;
  padding: 5rem 0;
  color: white !important;
  margin-top: 20rem !important;
  margin-right: 10rem !important;
}

.editor-edit-tag {
  position: absolute;
  background: rgb(196, 73, 198);
  top: 0;
  right: 10rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 15rem;
  color: white;
  text-transform: uppercase;
  right: 0;
  width: 50rem;
  height: 50rem;
  border-radius: 50%;
  transform: translate(50%, -50%);
  border-bottom-left-radius: 2rem;
  text-align: center;
  font-size: 18rem;
  cursor: context-menu;
  filter: drop-shadow(0 0 4rem rgba(85, 34, 34, 0.17));
  filter: var(--shadow-filter);
}
