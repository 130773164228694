.spinner {
  animation: rotate 1s infinite both;

  &__circle {
    width: 320rem;
    height: 320rem;
    border-radius: 50%;
    background: var(--primary);

    &--icon {
      width: 200rem;
      height: 200rem;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(180deg);
    }
  }
}
