.payment-selection {
  &__types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30rem;
    padding: 50rem 0;
    width: 100%;
    justify-items: center;
    justify-content: center;
  }

  &__type {
    border-radius: 20rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background: var(--secondary);
    filter: var(--shadow-filter);
    position: relative;

    &--image {
      margin: auto;
      align-self: center;
      max-width: 360rem;
    }

    &--payment-image {
      margin: 40rem auto;
    }

    &--text {
      width: 100%;
      padding: 30rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--name {
      text-align: left;
      font-size: 46rem;
    }
  }

  &__single-item {
    grid-template-columns: 1fr;

    .payment-selection__type {
      border-radius: 20rem;
      max-width: 450rem;
      max-height: unset;
    }
  }

  &__footer {
    .footer {
      height: 120rem;
    }
  }
}


.payment-selection-listing {
  max-height: 1300rem;
}

.ada {
  .payment-selection__types {
    flex: 1;
    width: 65%;
    padding: 40rem 0;
    margin: auto;
  }

  .payment-selection__type--payment-image {
    max-width: 180rem;
    margin: 20rem auto;
  }

  .payment-selection__type--text {
    padding: 15rem;
  }

  .payment-selection__type--name {
    font-size: 36rem;
  }

  .payment-selection-listing {
    max-height: 790rem;
  }
}
