.email-receipt-page {
  &__title {
    margin-top: 150rem;
  }
}

.ada {
  .email-receipt-page {
    &__title {
      margin-top: 0;
    }
  }
}
