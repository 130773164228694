.osiris-order-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 30rem;
  border-radius: 18rem;
  background: var(--secondary);
  border: 6rem solid var(--accent);

  &__header,
  &__footer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__header {
    margin: 0 30rem;
    padding: 12rem 0;
  }

  &__footer {
    padding: 10rem 30rem;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 20rem 0;
    padding: 0 30rem;
  }

  &__header {
    border-bottom: 2rem solid var(--gray);
  }

  &__product-img {
    max-width: 100rem;
  }

  &__product-title {
    padding: 0 30rem;
    font-size: 30rem;
  }

  &__actions {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 70rem;
  }

  &__button {
    border-radius: 0;
    min-height: unset;
    width: 100%;
    height: 100%;
    font-size: 34rem;
    text-transform: uppercase;

    &--reorder {
      background: var(--primary);
      color: var(--secondary);
      border-bottom-left-radius: 11rem;
    }

    &--full-order {
      background: var(--accent);
      color: var(--primary);
      border-bottom-right-radius: 11rem;
    }
  }
}
