.promo-stepper {
  &__page-container {
    padding: 100rem 0 0 0;
  }

  &__actions {
    gap: 10rem;
    min-height: 200rem;
  }
}
