.receipt-options {
  &__header-title {
    margin-bottom: 150rem;
  }
  &__container {
    margin-top: 150rem;
  }
  &__receipt-option-button {
    font-size: 50rem;
    margin-bottom: 40rem;
  }
}

.ada {
  .receipt-options {
    &__header-title {
      margin-bottom: 50rem;
    }
    &__container {
      margin-top: 50rem;
    }
    &__receipt-option-button {
      font-size: 40rem;
      margin-bottom: 20rem;
    }
  }
}
