.slide-show {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;

  &__item {
    object-fit: cover;
    width: 100%;

    &--animated {
      animation: fadeIn 700ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
  }
}
