.qr-code-pin-attempts {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;

  &__message {
    text-align: center;
    font-size: 40rem;
  }

  &__qrcode-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30rem;
  }
}
