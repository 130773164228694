html.ada {
  font-size: 0.04vh; // 1 rem for Full HD resolution

  .logo {
    max-width: 150rem;
  }

  .page-container,
  .page-container--grid {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50vh;
  }

  .page-header {
    padding: 24rem 0;
  }

  // !!! We don't want the main-grid to be positioned absolute inside popups
  .popup {
    &__page-container {
      position: static;
      height: 100%;
    }

    &__content {
      position: absolute;
      bottom: 50rem;
      max-height: 45vh;
      max-width: 75vw;
    }
  }

  .welcome {
    .title {
      font-size: 100rem;
    }

    .subtitle {
      font-size: 54rem;
    }
  }

  .footer {
    &__cart {
      height: 224rem;
    }

    &__left {
      justify-content: center;
    }
  }

  .buttons-list {
    .products-grid {
      padding: 10rem 40rem;
      grid-template-rows: minmax(300rem, 1fr);
      grid-template-columns: repeat(4, 1fr);
    }

    .product {
      img {
        max-width: 120rem;
      }
    }
  }

  .product {
    transition: none;

    &__title {
      font-size: 24rem;
    }
  }

  .modifiers-list {
    grid-template-rows: 70rem auto;

    &.withoutHeader {
      grid-template-rows: unset;
    }
  }

  .nav-slider {
    &__wrapper {
      height: 100%;
    }

    .nav-slider-item {
      &__title {
        font-size: 24rem;
        transition: none;
      }
    }
  }

  .circle {
    width: 300rem;
    height: 300rem;

    &.small {
      width: 250rem;
      height: 250rem;
    }
  }

  .quantity-info__actions {
    padding: 8rem 0;
  }
  .icon-pos--error {
    max-height: 400rem;
  }
}
