@font-face {
  font-family: 'DOT Default';
  src: url('/Channels/skin/default.woff');
  font-style: normal;
}

@font-face {
  font-family: 'DOT Light';
  src: url('/Channels/skin/light.woff');
  font-style: normal;
}

@font-face {
  font-family: 'DOT Medium';
  src: url('/Channels/skin/secondary.otf');
  font-style: normal;
}
