.preorder {
  &__image {
    max-width: 250rem;
  }
}

.ada {
  .preorder__square {
    width: 400rem;
    height: 400rem;
  }
}
