.voucher-info {
  padding: 0 40rem;
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  min-height: 112rem;
  background: var(--secondary);

  &--positioned-top {
    position: absolute;
    top: 190rem;
    z-index: 1;
  }

  &--button {
    font-size: 26rem;
  }
}
