$sliderItemWidth: 240rem;
$sliderItemHeight: 200rem;

.nav-slider {
  height: 190rem;

  position: relative;

  &__wrapper {
    width: 100%;
    height: 100%;
    background: var(--navSliderBackground);
  }

  &__container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: $sliderItemWidth;
    overflow-x: overlay;
    scroll-behavior: smooth;
  }

  &__controller {
    position: absolute;
    background: var(--primary);
    color: var(--secondary);
    height: 100%;
    top: 0;
    width: 60rem;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10rem 20rem;

    &--title {
      font-size: 28rem;
      text-transform: uppercase;
      color: var(--navSliderItemTitleColor);
    }

    &--image {
      max-width: 100rem;
    }

    &--selected {
      position: relative;
      background: var(--navSliderItemSelectedBackground);

      .nav-slider__item {
        &--title {
          color: var(--navSliderItemSelectedTitleColor);
        }
      }
    }

    &--displayOnlyImage {
      padding: 0;

      .nav-slider__item--selected {
        .nav-slider__item--title {
          display: none;
        }
      }

      .nav-slider__item--image {
        max-width: $sliderItemWidth;
        padding: 30rem;
      }
    }

    &--displayOnlyText {
      .nav-slider__item--image {
        display: none;
      }
    }
  }

  &__firstItemFixed {
    .nav-slider__wrapper {
      position: relative;
      padding-left: $sliderItemWidth;
    }

    .nav-slider__controller {
      &--left {
        left: $sliderItemWidth;
      }
    }

    .nav-slider__item {
      &--home {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: $sliderItemWidth;
        box-shadow: 6rem 1rem 13rem -5rem #33333345;

        &--selected {
          position: absolute;
        }
      }
    }
  }

  &.hasNavArrows {
    .nav-slider__container {
      margin: 0 60rem;
      width: calc(100% - 60rem);
    }

    .nav-slider__wrapper {
      padding-right: 60rem;
    }
  }

  &.vertical {
    height: 100%;
    width: 190rem;

    .nav-slider__wrapper {
      padding-left: unset;
    }

    &.nav-slider__firstItemFixed {
      .nav-slider__wrapper {
        padding-top: 190rem;
      }
    }

    .nav-slider__container {
      grid-auto-flow: row;
      grid-auto-rows: minmax(205rem, 1fr);
      grid-auto-columns: unset;
    }

    .nav-slider__item--home {
      bottom: unset;
      width: 100%;
    }
  }
}

.ada {
  .nav-slider {
    &.horizontal {
      .nav-slider__container {
        grid-auto-columns: $sliderItemWidth;
      }

      .nav-slider__firstItemFixed {
        .nav-slider__wrapper {
          padding-left: $sliderItemWidth;
        }

        .nav-slider__controller {
          &--left {
            left: $sliderItemWidth;
          }
        }

        .nav-slider__item {
          &--home {
            width: $sliderItemWidth;
          }
        }
      }
    }
  }
}

.rtl {
  .nav-slider {
    &.horizontal {
      .nav-slider__firstItemFixed {
        .nav-slider__item--home {
          right: 0;
          left: unset;
        }

        .nav-slider__wrapper {
          padding-right: $sliderItemWidth;
          padding-left: unset;
        }
      }
    }
  }
}
