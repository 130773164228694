.table-service-entry {
  &__options {
    margin: auto 50rem;
  }

  &__payment-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30rem;
    padding: 60rem 0;
    justify-content: center;
  }

  &__payment-type {
    border-radius: 20rem;
    align-items: stretch;
    background: var(--secondary);
    filter: var(--shadow-filter);
    position: relative;

    &--image {
      margin: 50rem auto;
      flex: 1;
    }

    &--name {
      font-size: 46rem;
    }
  }
}

.ada {
  .table-service-entry__payment-types {
    width: 65%;
    margin: 0 auto;
  }

  .table-service-entry__payment-type--image {
    max-width: 180rem;
  }
}
