.ice-modifier {
  padding: 50rem;
  gap: 50rem;
  justify-content: center;

  &__product {
    --product-border-radius: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    width: 300rem;
    margin: 10rem 0;
    border-radius: var(--product-border-radius);
    padding: 25rem;
    filter: var(--shadow-filter);
    color: var(--tertiary);
    background-color: var(--secondary);
    position: relative;

    &--selected {
      box-shadow: inset 0 0 0 5rem var(--accent);
      filter: none;

      .ice-modifier__product-title--checkmark {
        display: block;
      }
    }
  }

  &__product-title {
    width: 100%;
    text-align: center;
    font-size: 32rem;
    line-height: 1;

    &--checkmark {
      display: none;
      position: absolute;
      top: -30rem;
      right: -20rem;
    }
  }

  &__product-img {
    max-width: 200rem;
    margin: 10rem auto;
  }
}

.rtl {
  .ice-modifier__product-title--checkmark {
    left: -20rem;
    right: unset;
  }
}
