.modifiers-stepper {
  &__content {
    height: 95%;
  }

  &__page-container {
    padding: 0;
    overflow-y: unset;
  }

  &__top-menu {
    display: flex;
    align-items: center;
    min-height: 120rem;
    padding: 0 40rem;
    position: relative;

    &--title {
      position: absolute;
      font-size: 40rem;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__actions {
    gap: 10rem;
    min-height: 200rem;
  }
}

.ada {
  .modifiers-stepper {
    &__content {
      max-width: 95%;
      height: 45vh;
    }

    .product-stepper-header {
      min-height: 400rem;
      background-position: bottom;

      &__product-img {
        max-width: 200rem;
        top: 40rem;
      }
    }
  }
}
