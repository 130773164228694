.scan {
  background: var(--secondary);
  filter: var(--shadow-filter);
  width: 340rem;
  height: 340rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20rem;
  margin-top: 34rem;
}
