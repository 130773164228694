.scrollable-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__scroll {
    flex: 1;

    overflow-y: auto;
    overflow-x: hidden;
  }

  &__scroll-nav {
    flex: 100rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondary);
    box-shadow: 1rem -13rem 17rem 0 rgb(0 0 0 / 5%);
    border-bottom: 3rem solid var(--primary);
    z-index: 1;
    width: 100%;

    &--text {
      text-transform: uppercase;
    }

    &--up,
    &--down {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__icon {
    background: var(--primary);
    border-radius: 50%;
    width: 60rem;
    height: 60rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 30rem;

    &--image {
      margin-bottom: -3rem;
    }
  }
}
