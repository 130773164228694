.electronic-receipt-page {
  &__message {
    min-height: 300rem;
  }

  &__continue-button-container {
    margin-top: 150rem;
  }

  &__receiptUrl {
    max-width: 300rem;
  }
}

.ada {
  .electronic-receipt-page {
    &__message {
      min-height: 200rem;
    }

    &__continue-button-contaiƒner {
      margin-top: 50rem;
    }
  }
}
