.footer {
  height: var(--footer-height);
  width: 100%;
  background: var(--footerLightBackgroundColor);
  display: grid;
  grid-template-columns: 2fr 210rem 2fr;
  align-items: center;
  justify-items: center;
  position: relative;

  &__left {
    padding-left: 80rem;
  }

  &__ada-button {
    position: absolute;
    left: 20rem;
    bottom: 0;
    top: 0;
  }

  &__ada-button-image {
    margin: 0;

    &--light {
      display: none;
    }
  }
}

.rtl {
  .footer {
    &__left {
      padding-right: 80rem;
      padding-left: unset;
    }

    &__ada-button {
      right: 20rem;
      left: unset;
    }
  }
}
