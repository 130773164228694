:root {
  --fontDefault: 'DOT Default';
  --fontSecondary: 'DOT Medium';
  --fontLight: 'DOT Light';

  // // Primary colors / Brand Colors
  --primary: #252323;
  --secondary: #ffffff;
  --accent: #cbe510;

  --mainBackgroundColor: #ffffff;
  --basketBackgroundColor: #252323;
  --codViewBackgroundColor: #252323;
  --footerLightBackgroundColor: #ffffff;
  --footerDarkBackgroundColor: #252323;
  --popupsFooterBackgroundColor: #252323;
  --popupsContentBackgroundColor: #ffffff;

  --uiButtonPrimaryColor: #ffffff;
  --uiButtonPrimaryBackgroundColor: #252323;
  --uiButtonPrimaryBorderColor: #ffffff;

  --uiButtonSecondaryColor: #252323;
  --uiButtonSecondaryBackgroundColor: #cbe510;
  --uiButtonSecondaryBorderColor: transparent;

  --uiButtonTertiaryBackgroundColor: transparent;
  --uiButtonTertiaryColor: #252323;
  --uiButtonTertiaryBorderColor: #252323;

  --uiButtonLinkColor: #b5b5b5;

  --iconPrimaryColor: #252323;
  --iconSecondaryColor: #ffffff;

  --iconQuantityColor: #b5b5b5;

  --navSliderBackground: #252323;
  --navSliderItemTitleColor: #ffffff;
  --navSliderItemSelectedBackground: #cbe510;
  --navSliderItemSelectedTitleColor: #252323;
  --comboStepperMeterColor: #959595;

  --black: #000000;
  --blue: #00b0f0;
  --purple: #c153cb;
  --green: #6bbe4e;
  --orange: #ee5b04;
  --shadow: #00000080;
  --tertiary: #252323;
  --red: #dd0017;
  --errorRed: #ff2b2b;
  --gray: #e5e5e5;
  --keyboardGray: #959595;
  --white: #ffffff;
  --darkGray: #323231;
  --lightGray: #b5b5b5;
  --backgroundLightGray: #f0ece3;
  --backgroundGray: #f4f4f4;
  --footer-height: 170rem;

  // Other
  --spinner-color: #898989;
  --box-shadow: 0 0 21rem 5rem rgba(204, 204, 204, 1);
  --shadow-filter: drop-shadow(0 0 4rem rgba(85, 34, 34, 0.17));
  --button-radius: 2rem;
  --popup-bg: rgba(0, 0, 0, 0.65);
  --banners-bg: #252323;

  //rgba modifiers

  /* same as --darkGray but as rgba */
  --darkGray-opacity-5: rgba(50, 50, 49, 0.5);

  // Backgrounds
  --product-bg: url('/assets/images/product-bg.png');
  --combo-bg: url('/Channels/skin/icon-combo-bg.png');
  --pay-tower-bg: url('/Channels/skin/image--pay-tower.png');

  --logo: var(--customLogo, url('/Channels/skin/logo.svg'));
}

// html[data-theme="dark"] {
//   --primary-bg: #000000;
//   --secondary-bg: #ffffff;
// }
