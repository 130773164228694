.combo-stepper {
  &__content {
    height: 95%;
  }

  &__container {
    overflow-y: unset;

    &--actions {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 200rem;
      padding: 0 30rem;
      border-bottom-left-radius: 18rem;
      border-bottom-right-radius: 18rem;
      background: var(--popupsFooterBackgroundColor, var(--primary));
      justify-content: space-between;
    }
  }

  &__top-menu {
    display: flex;
    align-items: center;
    min-height: 120rem;
    padding: 0 40rem;
    position: relative;

    &--title {
      position: absolute;
      font-size: 40rem;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.ada {
  .combo-stepper {
    &__content {
      max-width: 95%;
      height: 45vh;
    }

    .product-stepper-header {
      min-height: 400rem;
      background-position: bottom;

      &__product-img {
        max-width: 200rem;
        top: 40rem;
      }
    }
  }
}
