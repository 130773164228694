.service-types {
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30rem;
  }

  &__titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: auto;
    &--title {
      font-size: 170rem;
    }

    &--subtitle {
      font-size: 54rem;
    }
  }
}

.ada {
  .service-types__buttons {
    max-width: 60%;
    margin: 0 auto;
  }
}
