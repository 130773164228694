.basket-toggler {
  width: 210rem;
  height: 190rem;
  background: var(--secondary);
  filter: var(--shadow-filter);
  border-top-left-radius: 30rem;
  border-top-right-radius: 30rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  &--opened {
    top: 15vh;
    z-index: 11;
    border-radius: 30rem;
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-top: 36rem;
    position: relative;
  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 70rem;
    padding-left: 12rem;

    &--text {
      font-weight: normal;
      font-size: 24rem;
      font-family: var(--fontSecondary);
      text-align: left;
    }
  }

  &__line {
    height: 8rem;
    border-radius: 8rem;
    background: var(--accent);
    margin: 6rem auto;
    width: 150rem;
  }

  &__items-number {
    position: absolute;
    right: 38rem;
    top: 28rem;
    width: 60rem;
    height: 60rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--accent);
  }

  &__icon-chevron {
    position: absolute;
    top: 15rem;
    left: 50%;
    transform: translateX(-50%);

    &--opened {
      transform: rotate(180deg) translateX(50%);
      transition: all 0.35s ease-in-out;
    }
  }
}

.ada {
  .basket-toggler--opened {
    top: 20rem;
  }
}

.rtl {
  .basket-toggler__items-number {
    left: 38rem;
    right: unset;
  }
}
