.basket-suggestions {
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20rem 40rem;
  min-height: 200rem;

  &__wrapper {
    display: flex;
    gap: 30rem;
    flex: 1;
  }

  &__img {
    max-width: 180rem;
  }

  &__title {
    color: var(--light-gray);
    padding: 10rem 0;
  }

  &__button-title {
    padding: 4rem 0;
  }

  &__button-price {
    color: var(--light-gray);
  }
}
