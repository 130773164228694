// General basket card
.product-card {
  display: grid;
  grid-template-columns: 240rem auto;
  grid-gap: 30rem;
  width: 100%;
  height: 100%;
  padding: 20rem;
  background: var(--secondary);
  color: var(--primary);
  border-radius: 18rem;

  &__img {
    max-width: 150rem;
  }

  &__title {
    font-size: 50rem;
    padding-right: 20rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__contents-list {
    font-family: var(--fontSecondary);
    list-style-type: none;
    margin: 0;
    padding: 20rem 0;

    &--item {
      font-size: 24rem;
      margin-bottom: 8rem;
    }
  }

  &__actions {
    align-self: flex-start;
  }

  &__price {
    font-family: var(--fontSecondary);
    font-size: 42rem;
    text-align: right;
  }

  &--promo-card {
    grid-template-columns: unset;
    padding: 20rem 40rem;
  }

  &--basket-card {
    :host-context(html.touchlessEnabled) & {
      animation: none;
    }
  }

  &-sub-card-item {
    background: var(--secondary);
    filter: var(--shadow-filter);
    border-radius: 18rem;
    margin-top: 30rem;
  }
}

// We use this subcard for LinkToParent & Promo Bogo

.rtl {
  .product-card__price {
    text-align: left;
  }
}
