.scanning-face {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  height: 100%;
  width: 100%;

  &__message {
    font-size: 60rem;
  }

  &__scanning {
    margin: 0 auto;
    position: relative;
    z-index: 0;

    &--scan-bar {
      position: absolute;
      display: block;
      width: 131%;
      height: 15rem;
      background-color: #ff4b4b9e;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      animation: scan-animation-bar 0.75s infinite ease;
      animation-direction: alternate;
    }

    &--icon {
      z-index: 0;
      .mouth,
      .eyes {
        stroke-dasharray: 500;
        stroke-dashoffset: 1000;
        stroke-width: 4;
        stroke: #020202;
        fill: rgba(2, 2, 2, 0);
        animation: appear 3s linear 1 forwards;
      }
    }
  }
}

@keyframes scan-animation-bar {
  to {
    top: 90%;
  }
}

@keyframes appear {
  from {
    stroke-dashoffset: 500;
  }
  80% {
    fill: rgba(2, 2, 2, 0);
  }
  90% {
    stroke-dashoffset: 0;
    fill: rgba(2, 2, 2, 1);
  }
  to {
    stroke-dashoffset: 0;
    fill: rgba(2, 2, 2, 1);
  }
}
