.osiris-order-number-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 30rem;

  &__save-button {
    background: var(--secondary);
    filter: var(--shadow-filter);
    padding: 20rem 40rem;
    display: flex;
    align-items: center;
    font-size: 60rem;
    margin: 0 auto;
    border-radius: 18rem;
    gap: 40rem;

    &--name {
      display: flex;
      gap: 11rem;
      margin-left: 40rem;
      color: var(--primary);
    }
  }
}
