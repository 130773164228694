.payment-logos {
  position: absolute;
  left: 0;
  bottom: 50rem;

  padding: 0 20rem;
  pointer-events: none;
  z-index: 1;

  &__image {
    margin: 0 20rem;
    max-width: 80rem;
  }
}
